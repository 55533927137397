import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import WMC_LOGO from "../../assets/brands/logo-sq.png"
import { colors } from "../../theme/colors"
import { createDownloadUrl, createStreamingUrl } from "../../util"

import BookmarkIcon from "@mui/icons-material/Bookmark"
import GetAppIcon from "@mui/icons-material/GetApp"
import ShareIcon from "@mui/icons-material/Share"
import ShortcutIcon from "@mui/icons-material/Shortcut"
import TurnedInIcon from "@mui/icons-material/TurnedIn"
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot"

import PauseIcon from "@mui/icons-material/Pause"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import Chinese from "../../theme/Chinese"
import English from "../../theme/English"
import { CartAPI, DownloadAPI, SavedMusicAPI, SubscriptionAPI } from "../../api"
import { streamRequest } from "../../api/request"
import { Buttons } from "../../component/MarketPlace/Table/Buttons"
import ShareToPopUp from "../../component/ShareToPopUp/ShareToPopUp"
import WaveMusicPlayer from "../../component/WaveMusicPlayer/WaveMusicPlayer"
import { useCart } from "../../context/CartProvider"
import { useUserInfo } from "../../context/UserInfoProvider"
import useMUIMediaQuery from "../../hooks/useMUIMedia"

type MusicDetailMusicProps = {
  _id: string
  name: string
  description: string
  imageUrl?: string
  image?: {
    downloadUrl: string
  }
  shareCount: number
  saveCount: number
  downloadCount: number
  ownedUser: string
  ownerId: string
  price: number
  downloadUrl: string
  watermarkUrl?: string
  purchased: boolean
  savedMusics: string[]
  musicType: string
  setSavedMusics: Dispatch<SetStateAction<string[]>>
}
/**
 * Music Detail Music Section
 */
export default function MusicDetailMusic(props: MusicDetailMusicProps) {
  const {
    _id,
    name,
    description,
    imageUrl,
    image,
    downloadCount,
    shareCount,
    saveCount,
    ownedUser,
    ownerId,
    price,
    purchased,
    savedMusics,
    setSavedMusics,
    musicType,
  } = props
  const { fetchCartItems } = useCart()
  const navigate = useNavigate()
  const projectImageUrl = createDownloadUrl(image?.downloadUrl)

  // New state for streaming audio URL
  const [streamAudioUrl, setStreamAudioUrl] = useState<string | null>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoadingAudio, setIsLoadingAudio] = useState(false)

  const { isMobile } = useMUIMediaQuery()
  const [subscribed, setSubscribed] = useState(false)

  const [openQuotaDialog, setOpenQuotaDialog] = useState(false)
  const [, setError] = useState("")
  const { userToken } = useUserInfo()
  const [isSaving, setIsSaving] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [isAddingToCart, setIsAddingToCart] = useState(false)

  // Load the streaming audio when component mounts
  useEffect(() => {
    const loadAudioStream = async () => {
      if (_id) {
        setIsLoadingAudio(true)
        try {
          const streamUrl = createStreamingUrl(_id)
          const audioBlob = await streamRequest({ url: streamUrl })
          setStreamAudioUrl(audioBlob)
        } catch (error) {
          console.error("Failed to load audio stream:", error)
        } finally {
          setIsLoadingAudio(false)
        }
      }
    }

    loadAudioStream()
  }, [_id])

  useEffect(() => {
    const fetchSavedMusics = async () => {
      try {
        const response = await SavedMusicAPI.getSavedMusic()
        if (response?.err) {
          console.error("Failed to get saved musics")
          return
        }
        console.log("Saved musics:", response)
        setSavedMusics(response.res.map((t: { musicId: { _id: string } }) => t.musicId._id))
      } catch (err) {
        console.error("Failed to get saved musics:", err)
      }
    }
    if (userToken) fetchSavedMusics()
  }, [setSavedMusics, userToken])

  const handleAddToCart = async (musicId: string) => {
    setIsAddingToCart(true)
    try {
      const response = await CartAPI.addToCart(musicId)
      if (response?.err) {
        console.error("Failed to add item to cart")
      } else {
        console.log("Item added to cart:", response)
        fetchCartItems()
      }
    } catch (err) {
      console.error("Failed to add item to cart:", err)
    } finally {
      setIsAddingToCart(false)
    }
  }

  const handleBuyNow = async (musicId: string) => {
    setIsAddingToCart(true)
    try {
      await handleAddToCart(musicId)
      navigate(`/shoppingCart`)
    } catch (err) {
      console.error("Failed during buy now process:", err)
      setIsAddingToCart(false)
    }
  }

  useEffect(() => {
    const checkSubscription = async () => {
      const response = await SubscriptionAPI.checkSubscription()
      if (response?.err) {
        setError(response?.err)
        return
      }
      setSubscribed(response?.res.success)
    }
    checkSubscription()
  }, [])

  const handleSaveMusic = async (musicId: string) => {
    setIsSaving(true)
    try {
      const response = await SavedMusicAPI.saveMusic(musicId)
      if (response?.err) {
        console.error("Failed to save music")
      } else {
        console.log("Music saved:", response)
        setSavedMusics([...savedMusics, musicId])
      }
    } catch (err) {
      console.error("Failed to save music:", err)
    } finally {
      setIsSaving(false)
    }
  }

  const handleUnsaveMusic = async (musicId: string) => {
    setIsSaving(true)
    try {
      const response = await SavedMusicAPI.unsaveMusic(musicId)
      if (response?.err) {
        console.error("Failed to unsave music")
      } else {
        console.log("Music unsaved:", response)
        const index = savedMusics.indexOf(musicId)
        if (index > -1) {
          setSavedMusics(savedMusics.filter((_, i) => i !== index))
        }
      }
    } catch (err) {
      console.error("Failed to unsave music:", err)
    } finally {
      setIsSaving(false)
    }
  }

  const saved = useMemo(() => savedMusics.includes(_id), [_id, savedMusics])

  const [openShareDialog, setOpenShareDialog] = useState(false)
  const [sharingMarketPlaceItem, setSharingMarketPlaceItem] =
    useState<MusicDetailMusicProps | null>(null)

  const handleShareClick = (item: MusicDetailMusicProps) => {
    setSharingMarketPlaceItem(item) // Set the selected item to share
    setOpenShareDialog(true) // Open the share dialog
  }

  const handleShareDialogClose = () => {
    setOpenShareDialog(false)
    setSharingMarketPlaceItem(null) // Clear the selected item after sharing
  }

  const handleDownload = async () => {
    setIsDownloading(true)
    let fileUrl = ""
    let url = ""
    try {
      const response = await DownloadAPI.recordDownload(_id)
      if (response?.err) {
        console.error("Failed to download music")
        setIsDownloading(false)
        return
      }
      if (response.res.success === false) {
        setOpenQuotaDialog(true)
        setIsDownloading(false)
        return
      }
      console.log("downloaded:", response)
      url = response.res.watermarkUrl ?? response.res.downloadUrl
    } catch (err) {
      console.error("Failed to download music:", err)
    }
    fileUrl = createDownloadUrl(url)

    if (!fileUrl) return

    try {
      // Fetch the file as a blob
      const response = await fetch(fileUrl)
      if (!response.ok) {
        throw new Error("Network response was not ok")
      }
      const blob = await response.blob()

      // Create a temporary URL
      const url = URL.createObjectURL(blob)

      // Create a link element
      const a = document.createElement("a")
      a.href = url
      // Set a default file name or use something dynamic
      a.download = name ? `${name}${purchased ? "" : "-wm"}.mp3` : "downloaded_file.mp3"
      document.body.appendChild(a)
      a.click()

      // Clean up
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
    } catch (error) {
      console.error("Download failed:", error)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <Grid container marginTop={2} textAlign="left">
      <Grid item container>
        <Grid item xs={12} sm={3} paddingRight={1}>
          <img src={projectImageUrl || imageUrl || WMC_LOGO} alt="project img" width="100%" />
        </Grid>
        <Grid item container xs={12} sm={9}>
          <Grid item xs={9}>
            <Box display={"flex"} alignItems="center" mb={isMobile ? 1 : 0}>
              <Typography variant="h5">{name}</Typography>
              {isLoadingAudio ? (
                <CircularProgress size={24} />
              ) : isPlaying ? (
                <IconButton aria-label="pause-button" onClick={() => setIsPlaying(false)}>
                  <PauseIcon />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="player-button"
                  onClick={() => setIsPlaying(true)}
                  disabled={!streamAudioUrl}
                >
                  <PlayArrowIcon />
                </IconButton>
              )}
            </Box>
            <Typography variant="body2">{description}</Typography>
            <Box display={"flex"} gap={1} mb={isMobile ? 1 : 0}>
              <Typography variant="body2" color={colors.grey}>
                <English>Owned by </English>
                <Chinese>創作者</Chinese>
              </Typography>
              <Typography
                variant="body2"
                color={colors.orange}
                onClick={() => navigate(`/user/${ownerId}/portfolio`)}
              >
                {ownedUser ?? ""}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={isMobile ? 1 : 0}>
              <Box alignItems="center" flex={1} display={"flex"} sx={{ color: colors.grey }}>
                <GetAppIcon />
                <Typography sx={{ ml: "2px" }} variant="body2">
                  {downloadCount}
                </Typography>
              </Box>
              <Box
                alignItems="center"
                flex={1}
                sx={{ ml: "15px", display: "flex", color: colors.grey }}
              >
                <BookmarkIcon />
                <Typography sx={{ ml: "2px" }} variant="body2">
                  {saveCount}
                </Typography>
              </Box>
              <Box
                alignItems="center"
                flex={1}
                sx={{ ml: "15px", display: "flex", color: colors.grey }}
              >
                <ShareIcon />

                <Typography sx={{ ml: "2px" }} variant="body2">
                  {shareCount}
                </Typography>
              </Box>
              <Box flex={1} sx={{ ml: "15px" }}>
                <IconButton
                  edge="start"
                  sx={{
                    padding: "4px, 10px, 4px, 10px",
                    color: colors.orange,
                  }}
                  onClick={() => handleShareClick(props)}
                >
                  <ShortcutIcon />
                  {!isMobile && (
                    <Typography sx={{ ml: "2px", color: colors.orange }}>
                      <English>Share</English>
                      <Chinese>分享</Chinese>
                    </Typography>
                  )}
                </IconButton>
              </Box>
              <Box flex={1} sx={{ ml: "15px" }}>
                <IconButton
                  edge="start"
                  sx={{
                    padding: "4px, 10px, 4px, 10px",
                    color: colors.orange,
                  }}
                  onClick={() => {
                    savedMusics.includes(_id) ? handleUnsaveMusic(_id) : handleSaveMusic(_id)
                  }}
                  disabled={isSaving}
                >
                  {isSaving ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : saved ? (
                    <TurnedInIcon color="primary" />
                  ) : (
                    <TurnedInNotIcon />
                  )}
                  {!isMobile && (
                    <Typography sx={{ ml: "2px", color: saved ? colors.orange : undefined }}>
                      {saved ? (
                        <>
                          <English>Saved</English>
                          <Chinese>已收藏</Chinese>
                        </>
                      ) : (
                        <>
                          <English>Save</English>
                          <Chinese>收藏</Chinese>
                        </>
                      )}
                    </Typography>
                  )}
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-start"
            mb={isMobile ? 2 : 0}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="body2"
                bgcolor={colors.grey3}
                mr={1}
                padding={1}
                sx={{ height: "36px", display: "flex", alignItems: "center" }}
              >
                {musicType === "STANDARD" ? (
                  <>
                    <English> STANDARD</English> <Chinese>基礎</Chinese>
                  </>
                ) : musicType === "SOUNDEFFECT" ? (
                  <>
                    <English> SOUNDEFFECT</English> <Chinese>音效</Chinese>
                  </>
                ) : musicType === "FREE" ? (
                  <>
                    <English> FREE</English> <Chinese>免費</Chinese>
                  </>
                ) : purchased ? (
                  <>
                    <English> PURCHASED</English> <Chinese>已購買</Chinese>
                  </>
                ) : (
                  `HKD ${price ?? 0}`
                )}
              </Typography>
              {purchased ||
              musicType === "STANDARD" ||
              musicType === "SOUNDEFFECT" ||
              musicType === "FREE" ? (
                <Buttons.Download onClick={handleDownload} isLoading={isDownloading} />
              ) : (
                <Buttons.BuyNowOrAddToCart
                  onBuyNowClick={() => handleBuyNow(_id)}
                  onAddToCartClick={() => handleAddToCart(_id)}
                  isLoading={isAddingToCart}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} mb={isMobile ? 2 : 0}>
            {streamAudioUrl && (
              <WaveMusicPlayer
                playingAudio={isPlaying}
                waveformHeight={isMobile ? 30 : 60}
                volume={0.5}
                audioUrl={streamAudioUrl}
              />
            )}
          </Grid>
          <Grid container justifyContent={"center"}>
            {!subscribed && (
              <Grid>
                <Button
                  variant="outlined"
                  onClick={() => navigate("/shoppingCart?subscriptionType=yearlyStandard")}
                >
                  <English>SUBSCRIBE FOR UNLIMITED DOWNLOAD TRACKS</English>
                  <Chinese>訂閱以獲得無限下載曲目</Chinese>
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {sharingMarketPlaceItem && (
        <ShareToPopUp
          open={openShareDialog}
          onClose={handleShareDialogClose}
          musicId={sharingMarketPlaceItem._id}
          musicName={sharingMarketPlaceItem.name}
        />
      )}
      <Dialog open={openQuotaDialog} onClose={() => setOpenQuotaDialog(false)}>
        <DialogTitle>Download Quota Reached</DialogTitle>
        <DialogContent>You have reached your download quota this month. Upgrade Now!</DialogContent>
        <DialogActions>
          {/* <Button onClick={() => navigate("/subscription")}>Upgrade Plan</Button> */}

          <Button onClick={() => setOpenQuotaDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}
