import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import {
  Button,
  Grid,
  SelectChangeEvent,
  Slider,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { useLanguage } from "../../context/LanguageProvider"
import * as React from "react"
import { Dropdown, ImageCrop } from "../../component"
import { ImageCropModal } from "../../component/ImageCrop/ImageCrop"
import { MUSIC_TYPES, musicKeys, tempoMarks } from "../../pages/StartProject/projectData"
import Chinese from "../../theme/Chinese"
import English from "../../theme/English"

interface ProjectBasicInfoProps {
  handleSelectChange: (_: SelectChangeEvent<string>) => void
  handleMetreChange: (_: SelectChangeEvent<string>) => void
  handleTextChange: (_: React.ChangeEvent<HTMLInputElement>) => void
  handleNumberChange?: (_: React.ChangeEvent<HTMLInputElement>) => void // Not yet in edit EditProjectDetailPage
  handleDateChange: (_: string | null) => void
  handleTempoChange: (_: number) => void
  handleUploadImage: (_: File | null) => void
  handlePreviewImage: (_: string) => void
  handleMusicTypeChange: (_: string) => void
  disabled?: boolean
  name: string
  description: string
  deadline: string
  tempo: number
  metreUpper: number
  metreLower: number
  musicKey: string
  musicType: string
  currency: string
  price: string
  imageUrl: string | undefined
}

/**
 * Project basic info
 */
export default function ProjectBasicInfo({
  handlePreviewImage,
  handleSelectChange,
  handleTextChange,
  handleNumberChange,
  handleDateChange,
  handleUploadImage,
  handleTempoChange,
  handleMetreChange,
  handleMusicTypeChange,
  disabled = false,
  name,
  description,
  deadline,
  tempo,
  metreUpper,
  metreLower,
  musicKey,
  musicType,
  currency,
  price,
  imageUrl = "",
}: ProjectBasicInfoProps) {
  const profileImgInputRef = React.useRef<HTMLInputElement>(null)
  const [open, setOpen] = React.useState(false)
  const [imgSrc, setImgSrc] = React.useState(imageUrl)
  const [tempImgSrc, setTempImgSrc] = React.useState(imageUrl) // Remain temporary image state for image crop and image preview

  const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setOpen(true)
      const reader = new FileReader()
      reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const handleSaveImage = (url: string, file: File) => {
    setImgSrc(url)
    setTempImgSrc(url)
    handlePreviewImage(url)
    handleUploadImage(file)
    setOpen(false)
  }
  const { language } = useLanguage()
  return (
    <Grid
      container
      spacing={{ xs: 2, lg: 6 }}
      sx={{
        mt: 2,
        mb: 6,
        "& .MuiTextField-root": { my: 2 },
        "& .MuiFormControl-root": { my: 2 },
      }}
    >
      <ImageCropModal open={open}>
        <Stack>
          <ImageCrop imgSrc={imgSrc} handleSaveImage={handleSaveImage} />
        </Stack>
      </ImageCropModal>
      <Grid item xs={12} lg={2}>
        <Box sx={{ position: "relative", mt: 2 }}>
          <input
            ref={profileImgInputRef}
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            onChange={handleAddImage}
            accept={"image/*"}
          />
          <label htmlFor="contained-button-file">
            <Button
              component="span"
              sx={{
                width: 200,
                height: 200,
                borderStyle: "dashed",
                borderWidth: 5,
                p: 1,
                textAlign: "center",
              }}
            >
              {tempImgSrc ? (
                <img src={open ? tempImgSrc : imgSrc} alt="imagePreview" width="100%" />
              ) : (
                <Box sx={{ alignItems: "center" }}>
                  <AddCircleOutlineIcon sx={{ width: 40, height: 40 }} />
                  <Typography>
                    <English>Project / Music Thumbnail</English>
                    <Chinese>專案 / 音樂縮圖</Chinese>
                  </Typography>
                </Box>
              )}
            </Button>
          </label>
        </Box>
      </Grid>

      <Grid item xs={12} lg={5}>
        <TextField
          required
          label={
            <>
              <English>Title</English>
              <Chinese>標題</Chinese>
            </>
          }
          fullWidth
          name="name"
          value={name}
          onChange={handleTextChange}
          disabled={disabled}
        />
        <TextField
          required
          label={
            <>
              <English>Description</English>
              <Chinese>描述</Chinese>
            </>
          }
          fullWidth
          name="description"
          value={description}
          onChange={handleTextChange}
          disabled={disabled}
          multiline
          rows={4}
        />

        <Typography sx={{ my: 1 }}>
          <English>Music Type</English>
          <Chinese>音樂類型</Chinese>
        </Typography>
        <ToggleButtonGroup
          exclusive
          value={musicType as string}
          color="primary"
          orientation={"horizontal"}
          onChange={(_, value) => handleMusicTypeChange(value)}
          sx={{ gap: 4 }}
        >
          {MUSIC_TYPES.map((item) => (
            <MusicTypeToggleButton key={item} value={item} />
          ))}
        </ToggleButtonGroup>

        {musicType === "PREMIUM" && (
          <Stack
            direction={{ xs: "column", lg: "row" }}
            spacing={4}
            sx={{ my: 1, mb: -2 }} // compensate last component row margin bottom
          >
            <Box flex={1}>
              <Dropdown
                value={currency as string}
                label={language === "zh" ? "幣別" : "Currency"}
                onChange={handleSelectChange}
                options={["HKD"]}
                name="currency"
                disabled={disabled}
              />
            </Box>
            <TextField
              required={musicType === "PREMIUM"}
              label={
                <>
                  <English>Price</English>
                  <Chinese>價格</Chinese>
                </>
              }
              fullWidth
              name="price"
              value={price}
              onChange={handleNumberChange}
              disabled={disabled}
              sx={{ flex: 1 }}
            />
          </Stack>
        )}
      </Grid>

      <Grid item xs={12} lg={5}>
        <Dropdown
          value={musicKey}
          label={language === "zh" ? "調性" : "Music Key"}
          onChange={handleSelectChange}
          options={musicKeys}
          name="musicKey"
          disabled={disabled}
        />

        <Typography>
          <English>Tempo</English>
          <Chinese>速度</Chinese>
        </Typography>
        <Grid container item xs={12}>
          <Stack direction="row" alignItems="center" flex={1} spacing={4}>
            <Slider
              value={tempo}
              onChange={(e, value) => handleTempoChange(value as number)}
              min={60}
              max={160}
              marks={tempoMarks}
              valueLabelFormat={() => String(tempo)}
              sx={{ flex: 3, m: 0 }}
            />
            <TextField
              required
              variant="standard"
              fullWidth
              name="tempo"
              value={tempo}
              onChange={handleTextChange}
              disabled={disabled}
              type="number"
              sx={{ flex: 1 }}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} lg={12}>
          <Dropdown
            value={`${metreUpper}/${metreLower}`}
            label={language === "zh" ? "拍號" : "Metre"}
            onChange={handleMetreChange}
            options={[
              "1/4",
              "2/4",
              "3/4",
              "4/4",
              "3/8",
              "6/8",
              "9/8",
              "12/8",
              `${metreUpper}/${metreLower}`,
            ]}
            name="Metre"
            disabled={disabled}
          />

          <DatePicker
            label={
              <>
                <English>Deadline</English>
                <Chinese>截止日期</Chinese>
              </>
            }
            value={deadline}
            onChange={handleDateChange}
            disabled={disabled}
            inputFormat="MM/dd/yyyy"
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

/**
 * Toggle button for music type selection
 */
// eslint-disable-next-line react/no-multi-comp
function MusicTypeToggleButton(props: any) {
  let label = <>--</>
  if (props.value === "PREMIUM")
    label = (
      <>
        <English>Premium</English>
        <Chinese>精選</Chinese>
      </>
    )
  if (props.value === "STANDARD")
    label = (
      <>
        <English>Standard</English>
        <Chinese>基礎</Chinese>
      </>
    )

  if (props.value === "SOUNDEFFECT")
    label = (
      <>
        <English>Sound Effect</English>
        <Chinese>音效</Chinese>
      </>
    )

  if (props.value === "FREE")
    label = (
      <>
        <English>Free</English>
        <Chinese>免費</Chinese>
      </>
    )

  return (
    <Grid item xs={12}>
      <ToggleButton {...props} size="small" sx={{ minWidth: 160 }}>
        {label}
      </ToggleButton>
    </Grid>
  )
}
