import React from "react"
import { Grid, Typography } from "@mui/material"
import { InstrumentList, MusicStyleList, MoodList, ThemeList } from ".."
import English from "../../theme/English"
import Chinese from "../../theme/Chinese"

interface ProjectInstrumentsProps {
  handleCheckBoxToggleChange?: (_event: React.ChangeEvent<HTMLInputElement>, _key: string) => void
  handleAddInstrument?: (_selected: string) => void
  handleRemoveInstrument?: (_selected: string) => void
  selectedStyles: string[]
  selectedInstruments?: string[]
  selectedMoods: string[]
  selectedThemes: string[]
}

/**
 * Instrumentation and Style for Project form
 */
export default function ProjectInstruments({
  handleAddInstrument = () => {},
  handleRemoveInstrument = () => {},
  handleCheckBoxToggleChange = () => {},
  selectedStyles,
  selectedInstruments,
  selectedMoods = [],
  selectedThemes = [],
}: ProjectInstrumentsProps) {
  return (
    <>
      <Typography gutterBottom variant="formTitle">
        <English>Instrumentation & Style</English>
        <Chinese>樂器和風格</Chinese>
      </Typography>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <Typography gutterBottom variant="body1">
            <English>Instruments</English>
            <Chinese>樂器</Chinese>
          </Typography>
          <InstrumentList
            selectedInstruments={selectedInstruments}
            handleAddInstrument={handleAddInstrument}
            handleRemoveInstrument={handleRemoveInstrument}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography gutterBottom variant="body1">
            <English>Music Styles</English>
            <Chinese>音樂風格</Chinese>
          </Typography>
          <MusicStyleList
            styleOfInterest={selectedStyles}
            stateKey="instruments"
            handleCheckBoxToggleChange={handleCheckBoxToggleChange}
            displayTitle={false}
            cols={{
              xs: 12,
              md: 6,
            }}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography gutterBottom variant="body1">
            <English>Mood</English>
            <Chinese>情感氛圍</Chinese>
          </Typography>
          <MoodList
            moods={selectedMoods}
            stateKey="moods"
            handleCheckBoxToggleChange={handleCheckBoxToggleChange}
            displayTitle={false}
            cols={{
              xs: 12,
              md: 6,
            }}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography gutterBottom variant="body1">
            <English>Themes</English>
            <Chinese>主題</Chinese>
          </Typography>
          <ThemeList
            themes={selectedThemes}
            stateKey="themes"
            handleCheckBoxToggleChange={handleCheckBoxToggleChange}
            displayTitle={false}
            cols={{
              xs: 12,
              md: 6,
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
