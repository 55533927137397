import React, { createContext, useContext, useState } from "react"
import { CartAPI } from "../api"
import { useUserInfo } from "./UserInfoProvider"

export type CartContextProps = {
  cartItems: any[]
  fetchCartItems: () => void
  removeFromCart: (_musicId: string) => Promise<void>
  removingItemId: string | null
}

const CartContext = createContext<CartContextProps | undefined>(undefined)

/**
 * CartProvider component
 */
export default function CartProvider({ children }: { children: React.ReactNode }) {
  const { userToken } = useUserInfo()
  const [cartItems, setCartItems] = useState<any[]>([])
  const [removingItemId, setRemovingItemId] = useState<string | null>(null)

  const fetchCartItems = async () => {
    if (!userToken) return

    try {
      const { res } = await CartAPI.getCart()
      setCartItems(res[0]?.items?.map((t: any) => ({ ...t.musicId })))
    } catch (error) {
      console.error("Failed to fetch cart items:", error)
    }
  }

  const removeFromCart = async (musicId: string) => {
    try {
      setRemovingItemId(musicId)
      await CartAPI.removeFromCart(musicId)
      setCartItems((prevItems) => prevItems.filter((item) => item._id !== musicId))
    } catch (error) {
      console.error("Failed to remove item from cart:", error)
    } finally {
      setRemovingItemId(null)
    }
  }

  return (
    <CartContext.Provider value={{ cartItems, fetchCartItems, removeFromCart, removingItemId }}>
      {children}
    </CartContext.Provider>
  )
}

/**
 * Custom hook to use the CartContext
 */
export function useCart() {
  const context = useContext(CartContext)
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider")
  }
  return context
}
