/* eslint-disable react/no-multi-comp */
import {
  Grid,
  SelectChangeEvent,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useLanguage } from "../../context/LanguageProvider"
import clone from "lodash/clone"
import pull from "lodash/pull"
import without from "lodash/without"
import React from "react"
import { PROJECT_TYPES } from "../../pages/StartProject/projectData"
import Chinese from "../../theme/Chinese"
import English from "../../theme/English"
import { ProjectFormStateProps } from "../../types"
import ProjectBasicInfo from "./ProjectBasicInfo"
import ProjectInstruments from "./ProjectInstruments"

interface ProjectFormProps {
  onSubmit: (_: ProjectFormStateProps) => void
  formState: ProjectFormStateProps
  setFormState: React.Dispatch<React.SetStateAction<ProjectFormStateProps>>
  isLoading?: boolean
  children?: React.ReactNode
  handleUploadImage: (_: File | null) => void
}

function ProjectTypeToggleButton(props: any) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("lg"))
  return (
    <>
      <Grid item xs={12} lg={4}>
        <ToggleButton {...props} fullWidth size="small">
          {props.value}
        </ToggleButton>
      </Grid>
      {matches && <Grid item sm={12} lg={1}></Grid>}
    </>
  )
}

/**
 *Project Form
 */
export default function ProjectForm({
  onSubmit,
  formState,
  setFormState,
  handleUploadImage,
}: ProjectFormProps) {
  const theme = useTheme()
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))
  const { language } = useLanguage()
  const {
    type,
    name,
    description,
    tempo,
    metreLower,
    metreUpper,
    deadline,
    musicKey,
    styles,
    instruments: insts,
    imageUrl,
    musicType,
    currency,
    price,
  } = formState

  const handleProjectTypeChange = (projectType: "NORMAL" | "SAMPLE") => {
    if (projectType) {
      setFormState((prevState) => ({
        ...prevState,
        type: projectType,
      }))
    }
  }

  const handlePreviewImage = (imageUrl: string) => {
    setFormState((prevState) => ({
      ...prevState,
      imageUrl,
    }))
  }

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setFormState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }

  const handleMetreChange = (event: SelectChangeEvent<string>) => {
    const [metreUpper, metreLower] = event.target.value.split("/")

    setFormState((prevState) => ({
      ...prevState,
      metreUpper: Number(metreUpper),
      metreLower: Number(metreLower),
    }))
  }

  const handleCheckBoxToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target
    let newState: string[]
    setFormState((prevState) => {
      if (checked) {
        //@ts-ignore
        newState = [...prevState.styles, value.toLowerCase()]
      } else {
        //@ts-ignore
        newState = without(prevState.styles, value.toLowerCase())
      }
      return {
        ...prevState,
        styles: newState,
      }
    })
  }

  const handleTempoChange = (tempo: number) => {
    setFormState((prevState) => ({
      ...prevState,
      tempo,
    }))
  }

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value.match(/^[0-9]*$/)) return null
    setFormState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }

  const handleMusicTypeChange = (musicType: string) => {
    if (musicType && musicType === "STANDARD") {
      setFormState((prevState) => ({
        ...prevState,
        musicType: musicType,
        currency: "",
        price: "",
      }))
    }
    if (musicType) {
      setFormState((prevState) => ({
        ...prevState,
        musicType: musicType,
      }))
    }
  }

  const handleDateChange = (date: string | null) => {
    if (date) {
      setFormState((prevState) => ({
        ...prevState,
        deadline: date,
      }))
    }
  }

  const handleAddInstrument = React.useCallback(
    (value: string) => {
      const instruments = clone(insts)
      const instrument = value.toLowerCase()
      instruments.push(instrument)
      setFormState((prevState) => ({
        ...prevState,
        instruments,
      }))
    },
    [insts, setFormState]
  )

  const handleRemoveInstrument = React.useCallback(
    (value: string) => {
      const instruments = clone(insts)
      const instrument = value.toLowerCase()
      pull(instruments, instrument)
      setFormState((prevState) => ({
        ...prevState,
        instruments,
      }))
    },
    [insts, setFormState]
  )

  return (
    <>
      <Typography gutterBottom variant="formTitle">
        <English>Project Type</English>
        <Chinese>專案類型</Chinese>
      </Typography>
      <Grid container item xs={12} lg={6} sx={{ mt: 2, mb: 6 }}>
        <ToggleButtonGroup
          color="primary"
          orientation={largeScreen ? "horizontal" : "vertical"}
          value={type}
          exclusive
          sx={{ flex: 1 }}
          onChange={(_, value) => handleProjectTypeChange(value)}
        >
          {PROJECT_TYPES.map((label) => (
            <ProjectTypeToggleButton
              key={label}
              value={language === "en" ? label : label === "NORMAL" ? "一般" : "範例"}
            />
          ))}
        </ToggleButtonGroup>
      </Grid>

      <Typography gutterBottom variant="formTitle">
        <English>Basic Info</English>
        <Chinese>基本資訊</Chinese>
      </Typography>
      <ProjectBasicInfo
        handlePreviewImage={handlePreviewImage}
        handleUploadImage={handleUploadImage}
        handleSelectChange={handleSelectChange}
        handleTextChange={handleTextChange}
        handleNumberChange={handleNumberChange}
        handleDateChange={handleDateChange}
        handleTempoChange={handleTempoChange}
        handleMetreChange={handleMetreChange}
        handleMusicTypeChange={handleMusicTypeChange}
        name={name}
        imageUrl={imageUrl}
        description={description}
        deadline={deadline}
        tempo={tempo}
        metreUpper={metreUpper}
        metreLower={metreLower}
        musicKey={musicKey}
        musicType={musicType}
        currency={currency}
        price={price}
      />
      <ProjectInstruments
        selectedInstruments={insts}
        selectedStyles={styles}
        selectedMoods={[]}
        selectedThemes={[]}
        handleCheckBoxToggleChange={handleCheckBoxToggleChange}
        handleAddInstrument={handleAddInstrument}
        handleRemoveInstrument={handleRemoveInstrument}
      />
    </>
  )
}
