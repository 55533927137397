import { Chip, FormControl, FormHelperText, FormLabel, Grid, Box } from "@mui/material"
import { GridSize } from "@mui/material/Grid"
import compact from "lodash/compact"
import startCase from "lodash/startCase"
import React, { useCallback } from "react"
import { useLanguage } from "../../context/LanguageProvider"
import { moodList } from "../UserForm/user"
import { translateMood } from "./moodData"

const moodListDefaultCol = {
  xs: 6, // 2 per row on mobile
  sm: 4, // 3 per row on tablet
  md: 3, // 4 per row on desktop
}

interface MoodListProps {
  handleCheckBoxToggleChange: (_event: React.ChangeEvent<HTMLInputElement>, _key: string) => void
  moods: string[]
  stateKey: string
  displayTitle?: boolean
  cols?: {
    xs?: GridSize
    sm?: GridSize
    md?: GridSize
    lg?: GridSize
  }
  editFormErrorDisplay?: (_: string) => void
  getErrorMessage?: (_key: string) => string | undefined
  shouldDisplayError?: (_: string) => boolean
}

/**
 * List out moods in WMC
 */
export default function MoodList({
  handleCheckBoxToggleChange,
  editFormErrorDisplay = () => {},
  getErrorMessage = () => undefined,
  shouldDisplayError = () => false,
  moods,
  stateKey,
  cols = moodListDefaultCol,
  displayTitle = true,
}: MoodListProps) {
  console.log(moodList)
  const displayError = !!(shouldDisplayError("moods") && getErrorMessage("moods"))
  const { language } = useLanguage()

  const createMoodOptions = useCallback(() => {
    return compact(moodList as string[]).map((value: string, index: number) => {
      const isSelected = moods.includes(value)

      return (
        <Chip
          key={`${value}${index}`}
          label={translateMood(value, language)}
          color="primary"
          variant={isSelected ? "filled" : "outlined"}
          onClick={() => {
            editFormErrorDisplay("moods")
            const event = {
              target: { value, checked: !isSelected },
            } as React.ChangeEvent<HTMLInputElement>
            handleCheckBoxToggleChange(event, stateKey)
          }}
          onDelete={
            isSelected
              ? () => {
                  editFormErrorDisplay("moods")
                  const event = {
                    target: { value, checked: false },
                  } as React.ChangeEvent<HTMLInputElement>
                  handleCheckBoxToggleChange(event, stateKey)
                }
              : undefined
          }
          sx={{ m: 0.5 }}
        />
      )
    })
  }, [language, moods, editFormErrorDisplay, handleCheckBoxToggleChange, stateKey])

  return (
    <Grid container item xs={12} sx={{ px: 2 }}>
      <FormControl component="fieldset" error={displayError} sx={{ width: "100%" }}>
        {displayTitle && <FormLabel component="legend">Moods</FormLabel>}
        {displayError && (
          <FormHelperText id="moods-errors">{getErrorMessage("moods")}</FormHelperText>
        )}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
            mt: 1,
          }}
          data-testid="moodList"
        >
          {createMoodOptions()}
        </Box>
      </FormControl>
    </Grid>
  )
}
