import { Box, Button, Divider, IconButton, SxProps, Theme, CircularProgress } from "@mui/material"
import DownloadIcon from "@mui/icons-material/Download"
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart"
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot"
import TurnedInIcon from "@mui/icons-material/TurnedIn"
import ShortcutIcon from "@mui/icons-material/Shortcut"
import Chinese from "../../../theme/Chinese"
import English from "../../../theme/English"
import { colors } from "../../../theme/colors"
import { useLanguage } from "../../../context/LanguageProvider"

const BUTTON_WIDTH = 140
const BUTTON_BORDER_RADIUS = 16

// Update the interface to include isLoading property
interface ButtonProps {
  sx?: SxProps<Theme>
  onClick?: () => void
  saved?: boolean
  isLoading?: boolean
  onBuyNowClick?: () => void
  onAddToCartClick?: () => void
}

/**
 * Download button in market place table
 */
function Download({ sx, onClick, isLoading }: ButtonProps) {
  const { language } = useLanguage()

  return (
    <Button
      sx={{
        ...sx,
        width: BUTTON_WIDTH,
        display: "inline-flex",
        alignItems: "center",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: BUTTON_BORDER_RADIUS,
        overflow: "hidden",
        textTransform: "none",
      }}
      startIcon={isLoading ? <CircularProgress size={20} /> : <DownloadIcon />}
      onClick={onClick}
      disabled={isLoading}
    >
      {isLoading ? (
        language === "zh" ? (
          "下載中..."
        ) : (
          "Downloading..."
        )
      ) : (
        <>
          <English>Download</English>
          <Chinese>下載</Chinese>
        </>
      )}
    </Button>
  )
}

/**
 * Buy now button in market place table
 */
// eslint-disable-next-line react/no-multi-comp
function BuyNowOrAddToCart({ sx, onBuyNowClick, onAddToCartClick, isLoading }: ButtonProps) {
  const { language } = useLanguage()

  return (
    <Box
      sx={{
        ...sx,
        width: BUTTON_WIDTH,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: BUTTON_BORDER_RADIUS,
        overflow: "hidden",
      }}
    >
      <Button
        sx={{ textTransform: "none" }}
        onClick={onBuyNowClick}
        disabled={isLoading}
        startIcon={isLoading ? <CircularProgress size={16} /> : null}
      >
        {isLoading ? (
          language === "zh" ? (
            "處理中..."
          ) : (
            "Processing..."
          )
        ) : (
          <>
            <English>Buy Now</English>
            <Chinese>立即購買</Chinese>
          </>
        )}
      </Button>
      <Divider orientation="vertical" flexItem />
      <Button onClick={onAddToCartClick} disabled={isLoading}>
        <AddShoppingCartIcon />
      </Button>
    </Box>
  )
}

/**
 * Share/ forward button in market place table
 */
// eslint-disable-next-line react/no-multi-comp
function Share({ sx, onClick, isLoading }: ButtonProps) {
  return (
    <IconButton sx={sx} onClick={onClick} disabled={isLoading}>
      {isLoading ? <CircularProgress size={20} /> : <ShortcutIcon />}
    </IconButton>
  )
}

/**
 * Bookmark button in market place table
 */
// eslint-disable-next-line react/no-multi-comp
function Bookmark({ sx, onClick, saved, isLoading }: ButtonProps) {
  return (
    <IconButton sx={sx} onClick={onClick} disabled={isLoading}>
      {isLoading ? (
        <CircularProgress size={20} />
      ) : saved ? (
        <TurnedInIcon sx={{ color: colors.orange }} />
      ) : (
        <TurnedInNotIcon />
      )}
    </IconButton>
  )
}

export const Buttons = {
  Download,
  BuyNowOrAddToCart,
  Share,
  Bookmark,
}
