import {
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
} from "@mui/material"
import Typography from "@mui/material/Typography"
import { colors } from "../../theme/colors"
import { createDownloadUrl } from "../../util"

import ShortcutIcon from "@mui/icons-material/Shortcut"
import TurnedInIcon from "@mui/icons-material/TurnedIn"
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot"
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CartAPI, DownloadAPI, ProjectAPI, SavedMusicAPI } from "../../api"
import WMC_LOGO from "../../assets/brands/logo-sq.png"
import BuyNowOrAddToCartButton from "../../component/BuyNowOrAddToCartButton/BuyNowOrAddToCartButton"
import DownloadButton from "../../component/DownloadButton/DownloadButton"
import ShareToPopUp from "../../component/ShareToPopUp/ShareToPopUp"
import { useCart } from "../../context/CartProvider"
import Chinese from "../../theme/Chinese"
import English from "../../theme/English"

type MusicDetailMusicCardProps = {
  _id: string
  name: string
  description: string
  imageUrl: string
  image: {
    downloadUrl: string
  }
  saved: boolean
  purchased: boolean
  price: number
  project: string
  savedMusics: string[]
  setSavedMusics: Dispatch<SetStateAction<string[]>>
  downloadUrl: string
  watermarkUrl: string
  musicType: string
}
/**
 * Music Detail Music Card Section
 */
export default function MusicDetailMusicCard(props: MusicDetailMusicCardProps) {
  const navigate = useNavigate()
  const { fetchCartItems } = useCart()
  const {
    _id,
    name,
    description,
    image,
    purchased,
    price,
    project,
    savedMusics,
    setSavedMusics,
    musicType,
  } = props

  const [openQuotaDialog, setOpenQuotaDialog] = useState(false)
  const [projectDetail, setProjectDetail] = useState({
    users: [] as { role: string; user: { name: string; _id: string } }[],
  })
  const [isSaving, setIsSaving] = useState(false) // Add loading state for save action
  const [isDownloading, setIsDownloading] = useState(false) // Add loading state for download
  const [isAddingToCart, setIsAddingToCart] = useState(false) // Add loading state for cart

  useEffect(() => {
    const fetchProject = async () => {
      if (project) {
        const res = await ProjectAPI.getProject(project)
        if (res?.err) console.error(res.err)
        else setProjectDetail(res.res)
      }
    }
    fetchProject()
  }, [project])

  const projectImageUrl = createDownloadUrl(image?.downloadUrl) || WMC_LOGO

  const handleAddToCart = async () => {
    setIsAddingToCart(true)
    try {
      const res = await CartAPI.addToCart(_id)
      if (!res?.err) fetchCartItems()
    } catch (error) {
      console.error("Failed to add to cart:", error)
    } finally {
      setIsAddingToCart(false)
    }
  }

  const handleBuyNow = async () => {
    setIsAddingToCart(true)
    try {
      await handleAddToCart()
      navigate(`/shoppingCart`)
    } catch (error) {
      console.error("Failed to process buy now:", error)
      setIsAddingToCart(false)
    }
  }

  const handleSaveMusic = async () => {
    setIsSaving(true)
    try {
      const res = await SavedMusicAPI.saveMusic(_id)
      if (!res?.err) setSavedMusics([...savedMusics, _id])
    } catch (error) {
      console.error("Failed to save music:", error)
    } finally {
      setIsSaving(false)
    }
  }

  const handleUnsaveMusic = async () => {
    setIsSaving(true)
    try {
      const res = await SavedMusicAPI.unsaveMusic(_id)
      if (!res?.err) setSavedMusics(savedMusics.filter((id) => id !== _id))
    } catch (error) {
      console.error("Failed to unsave music:", error)
    } finally {
      setIsSaving(false)
    }
  }

  const saved = useMemo(() => savedMusics.includes(_id), [_id, savedMusics])
  const [openShareDialog, setOpenShareDialog] = useState(false)

  const handleDownload = async () => {
    setIsDownloading(true)
    let fileUrl = ""
    let url = ""
    try {
      const response = await DownloadAPI.recordDownload(_id)
      if (response?.err) {
        console.error("Failed to download music")
        setIsDownloading(false)
        return
      }
      if (response.res.success === false) {
        setOpenQuotaDialog(true)
        setIsDownloading(false)
        return
      }
      console.log("downloaded:", response)
      url = response.res.watermarkUrl ?? response.res.downloadUrl
      fileUrl = createDownloadUrl(url)

      if (!fileUrl) {
        setIsDownloading(false)
        return
      }

      const fileResponse = await fetch(fileUrl)
      if (!fileResponse.ok) {
        throw new Error("Network response was not ok")
      }
      const blob = await fileResponse.blob()

      const downloadUrl = URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = downloadUrl
      a.download = name ? `${name}${purchased ? "" : "-wm"}.mp3` : "downloaded_file.mp3"
      document.body.appendChild(a)
      a.click()

      document.body.removeChild(a)
      URL.revokeObjectURL(downloadUrl)
    } catch (error) {
      console.error("Download failed:", error)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <Box
      sx={{
        position: "relative",
        maxWidth: 345,
        width: "100%",
        m: 1,
        backgroundColor: colors.darkGrey2,
        borderRadius: 2,
        boxShadow: 3,
        overflow: "hidden",
      }}
    >
      {/* Price Tag */}

      <Box
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          backgroundColor: colors.orange,
          color: "#fff",
          padding: "2px 8px",
          borderRadius: "8px",
          fontSize: "0.8rem",
          fontWeight: "bold",
        }}
      >
        {purchased ? (
          <>
            <English>PURCHASED</English>
            <Chinese>已購買</Chinese>
          </>
        ) : musicType === "STANDARD" ? (
          <>
            <English>STANDARD</English>
            <Chinese>基礎</Chinese>
          </>
        ) : musicType === "SOUNDEFFECT" ? (
          <>
            <English>Sound Effect</English>
            <Chinese>音效</Chinese>
          </>
        ) : musicType === "FREE" ? (
          <>
            <English>FREE</English>
            <Chinese>免費</Chinese>
          </>
        ) : (
          `HKD ${price ?? 0}`
        )}
      </Box>

      {/* Card Image */}
      <Link onClick={() => navigate(`/musicDetail/${_id}`)}>
        <CardMedia
          component="img"
          image={projectImageUrl}
          title="Project Image"
          sx={{
            height: { xs: 180, sm: 250, md: 300 },
            width: "100%",
            objectFit: "cover",
          }}
        />
      </Link>

      {/* Content */}
      <Box p={2}>
        <Typography variant="h6" color="white" gutterBottom>
          <Link
            sx={{ textDecoration: "none", color: "white", cursor: "pointer" }}
            onClick={() => navigate(`/musicDetail/${_id}`)}
          >
            {name}
          </Link>
        </Typography>
        <Typography variant="body2" color={colors.grey2}>
          {description}
        </Typography>
        <Typography
          variant="caption"
          color={colors.orange}
          sx={{ cursor: "pointer" }}
          onClick={() =>
            navigate(
              `/user/${
                projectDetail?.users?.find((t) => t?.role === "LEAD")?.user?._id ?? ""
              }/portfolio`
            )
          }
        >
          {projectDetail?.users?.find((t) => t?.role === "LEAD")?.user?.name ?? ""}
        </Typography>
      </Box>

      {/* Actions */}
      <Box display="flex" justifyContent="space-between" alignItems="center" px={2} pb={2}>
        {purchased ||
        musicType === "STANDARD" ||
        musicType === "SOUNDEFFECT" ||
        musicType === "FREE" ? (
          <DownloadButton onClick={handleDownload} isLoading={isDownloading} />
        ) : (
          <BuyNowOrAddToCartButton
            onBuyNowClick={handleBuyNow}
            onAddToCartClick={handleAddToCart}
            isLoading={isAddingToCart}
          />
        )}

        <Box display="flex">
          <IconButton onClick={() => setOpenShareDialog(true)}>
            <ShortcutIcon />
          </IconButton>
          <IconButton
            onClick={() => (saved ? handleUnsaveMusic() : handleSaveMusic())}
            disabled={isSaving}
          >
            {isSaving ? (
              <CircularProgress size={20} />
            ) : saved ? (
              <TurnedInIcon />
            ) : (
              <TurnedInNotIcon />
            )}
          </IconButton>
        </Box>
      </Box>

      {/* Share Dialog */}
      <ShareToPopUp
        open={openShareDialog}
        onClose={() => setOpenShareDialog(false)}
        musicId={_id}
        musicName={name}
      />
      <Dialog open={openQuotaDialog} onClose={() => setOpenQuotaDialog(false)}>
        <DialogTitle>
          <English>Download Quota Reached</English>
          <Chinese>下載配額已達上限</Chinese>
        </DialogTitle>
        <DialogContent>
          <English>You have reached your download quota this month. Upgrade Now!</English>
          <Chinese>您本月的下載配額已用盡。立即升級！</Chinese>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenQuotaDialog(false)}>
            <English>Close</English>
            <Chinese>關閉</Chinese>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
