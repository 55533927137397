import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import English from "../../theme/English"
import Chinese from "../../theme/Chinese"
import { colors } from "../../theme/colors"
import WMC_LOGO from "../../assets/brands/logo-sq.png"
import { UploadSongFormStateProps } from "../../types"
import _ from "lodash"
import { Stack } from "@mui/material"
import { translateMusicStyle } from "../../component/MusicStyleList/musicStyleData"
import { translateInstrumentText } from "../../component/InstrumentList/instrumentData"
import { useLanguage } from "../../context/LanguageProvider"
import { translateMood } from "../../component/MoodList/moodData"
import { translateTheme } from "../../component/ThemeList/themeData"

const infoContainerStyle = {
  " & h5": {
    color: colors.teal,
  },
  " & h6": {
    color: colors.grey,
  },
}

/**
 * Upload song info for preview
 */
export default function UploadSongInfo(
  props: UploadSongFormStateProps & { imagePreviewUrl: string }
) {
  const {
    name,
    description,
    tempo,
    metreLower,
    metreUpper,
    musicKey,
    styles,
    instruments,
    musicType,
    currency,
    price,
    imagePreviewUrl,
    moods,
    themes,
  } = props
  const { language } = useLanguage() // retrieve language state
  return (
    <Grid container sx={infoContainerStyle} spacing={{ xs: 0, lg: 4 }}>
      <Grid item xs={12} lg={3}>
        <img src={imagePreviewUrl || WMC_LOGO} alt="project img" width="100%" />
      </Grid>

      <Grid item container xs={12} lg={9} spacing={2}>
        <Grid item xs={12} pb={{ xs: 0, lg: 2 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4">{name ? name : "--"}</Typography>
            {musicType && (
              <Typography
                variant="body2"
                bgcolor={colors.grey3}
                p={1}
                borderRadius={1}
                alignSelf={"center"}
              >
                {musicType === "PREMIUM" ? (
                  `${currency} ${price}`
                ) : musicType === "STANDARD" ? (
                  <>
                    <English>Standard</English>
                    <Chinese>基礎</Chinese>
                  </>
                ) : musicType === "SOUNDEFFECT" ? (
                  <>
                    <English>Sound Effect</English>
                    <Chinese>音效</Chinese>
                  </>
                ) : musicType === "FREE" ? (
                  <>
                    <English>FREE</English>
                    <Chinese>免費</Chinese>
                  </>
                ) : (
                  "--"
                )}
              </Typography>
            )}
          </Stack>
          <Typography variant="body1">{description ? description : "--"}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">
            <English>Information</English>
            <Chinese>資訊</Chinese>
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2">
            <English>Style</English>
            <Chinese>風格</Chinese>
          </Typography>
          <Typography variant="body1">
            {_.isEmpty(styles)
              ? "--"
              : styles
                  ?.map((style: string) => translateMusicStyle(style, language)?.capitalize())
                  .join(", ")}
          </Typography>
        </Grid>

        <Grid item xs={12} md={8}>
          <Typography variant="subtitle2">
            <English>Instruments</English>
            <Chinese>樂器</Chinese>
          </Typography>
          <Typography variant="body1">
            {_.isEmpty(instruments)
              ? "--"
              : instruments
                  ?.map((instrument: string) =>
                    translateInstrumentText(
                      instrument.charAt(0).toUpperCase() + instrument.slice(1),
                      language
                    )
                  )
                  .join(", ")}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2">
            <English>Music Key</English>
            <Chinese>調性</Chinese>
          </Typography>
          <Typography variant="body1">{musicKey}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2">
            <English>Tempo</English>
            <Chinese>速度</Chinese>
          </Typography>
          <Typography variant="body1">{tempo}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2">
            <English>Metre</English>
            <Chinese>拍號</Chinese>
          </Typography>
          <Typography variant="body1">{`${metreUpper}/${metreLower}`}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2">
            <English>Moods</English>
            <Chinese>情感氛圍</Chinese>
          </Typography>
          <Typography variant="body1">
            {_.isEmpty(moods)
              ? "--"
              : moods
                  ?.map((mood: string) => translateMood(mood, language)?.capitalize())
                  .join(", ")}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2">
            <English>Themes</English>
            <Chinese>主題</Chinese>
          </Typography>
          <Typography variant="body1">
            {_.isEmpty(themes)
              ? "--"
              : themes
                  ?.map((theme: string) => translateTheme(theme, language)?.capitalize())
                  .join(", ")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
