import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import FolderSharedIcon from "@mui/icons-material/FolderShared"
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic"
import MailIcon from "@mui/icons-material/Mail"
import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import React from "react"
import { Link } from "react-router-dom"
// import HeadphonesIcon from "@mui/icons-material/Headphones"
import StorefrontIcon from "@mui/icons-material/Storefront"
import Button from "@mui/material/Button"
import { useUserInfo } from "../../context"
import { useLanguage } from "../../context/LanguageProvider"
import { useCheckIsAdmin } from "../../hooks/useCheckIsAdmin"
import NavItem from "../NavItem"

/**
 * Drawer Menus
 */
export default function DrawerMenu() {
  const { isAdmin } = useCheckIsAdmin()
  const { userToken } = useUserInfo()
  const isLoggedIn = Boolean(userToken)
  const { language } = useLanguage()

  const items = [
    {
      href: "/marketPlace",
      icon: StorefrontIcon,
      title_en: "Market Place",
      title_zh: "市集",
    },
    // {
    //   href: "/user/self/projects",
    //   icon: FolderSharedIcon,
    //   title_en: "My Projects",
    //   title_zh: "我的專案",
    //   divider: true,
    // },
    // {
    //   href: "/projects",
    //   icon: LibraryMusicIcon,
    //   title_en: "Projects Center",
    //   title_zh: "專案中心",
    // },
    {
      href: "/jobs",
      icon: FolderSharedIcon,
      title_en: "Jobs Center",
      title_zh: "工作中心",
      divider: true,
    },
    {
      href: "/contactus",
      icon: MailIcon,
      title_en: "Contact Us",
      title_zh: "聯絡我們",
      divider: true,
    },
  ]

  return (
    <div>
      <Divider />

      <List>
        {items?.map((item) => {
          const title = language === "zh" ? item.title_zh : item.title_en
          return (
            <React.Fragment key={title}>
              {item.divider && <Divider />}
              <NavItem href={item.href} title={title} icon={item.icon} />
            </React.Fragment>
          )
        })}
      </List>

      {isAdmin && (
        <Button
          disabled={!isLoggedIn && !isAdmin}
          onClick={() => {}}
          component={Link}
          to="/startProject"
          type="submit"
          color={"primary"}
          variant="contained"
          sx={{
            position: "absolute",
            left: 20,
            right: 20,
            bottom: "5vh",
            borderRadius: 30,
            pb: 1.5,
            pt: 1.5,
          }}
          endIcon={<ArrowForwardIcon />}
        >
          {language === "zh" ? "開始專案" : "Start Project"}
        </Button>
      )}
    </div>
  )
}
