/* eslint-disable react/no-multi-comp */
import { Grid, SelectChangeEvent, Typography, alpha } from "@mui/material"
import clone from "lodash/clone"
import pull from "lodash/pull"
import without from "lodash/without"
import React from "react"
import Dropzone from "react-dropzone-uploader"
import { useLanguage } from "../../context/LanguageProvider"
import Chinese from "../../theme/Chinese"
import English from "../../theme/English"
import { colors } from "../../theme/colors"
import { UploadSongFormStateProps } from "../../types"
import { acceptMusicFileExt } from "../../types/uploadFileTypes"
import ProjectInstruments from "../ProjectForm/ProjectInstruments"
import UploadSongBasicInfo from "./UploadSongBasicInfo"

interface UploadSongFormProps {
  onSubmit: (_: UploadSongFormStateProps) => void
  formState: UploadSongFormStateProps
  setFormState: React.Dispatch<React.SetStateAction<UploadSongFormStateProps>>
  isLoading?: boolean
  children?: React.ReactNode
  handleUploadMusicFile: (_: File | null) => void
  handleUploadImage: (_: File | null) => void
  //
  imagePreviewUrl: string
  setImagePreviewUrl: (_: string) => void
}

/**
 * Upload song from
 * Reuse ProjectInstruments from ProjectForm
 */
export default function UploadSongForm({
  onSubmit,
  formState,
  setFormState,
  handleUploadMusicFile,
  handleUploadImage,
  imagePreviewUrl, // for image preview only
  setImagePreviewUrl, // for image preview only
}: UploadSongFormProps) {
  const {
    name,
    description,
    tempo,
    metreLower,
    metreUpper,
    musicKey,
    styles,
    instruments: insts,
    moods,
    themes,
    musicType,
    currency,
    price,
  } = formState
  const { language } = useLanguage()
  const handleFileDrop = (
    fileDropParams: { meta: any; file: File },
    status: "done" | "removed"
  ) => {
    const { file } = fileDropParams
    if (status === "done") {
      handleUploadMusicFile(file)
    } else if (status === "removed") {
      handleUploadMusicFile(null)
    }
  }

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setFormState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }

  const handleMetreChange = (event: SelectChangeEvent<string>) => {
    const [metreUpper, metreLower] = event.target.value.split("/")

    setFormState((prevState) => ({
      ...prevState,
      metreUpper: Number(metreUpper),
      metreLower: Number(metreLower),
    }))
  }

  const handleCheckBoxToggleChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const { value, checked } = event.target
    let newState: string[]

    setFormState((prevState) => {
      if (key === "moods") {
        if (checked) {
          newState = [...prevState.moods, value.toLowerCase()]
        } else {
          newState = without(prevState.moods, value.toLowerCase())
        }
        return {
          ...prevState,
          moods: newState,
        }
      } else if (key === "themes") {
        if (checked) {
          newState = [...prevState.themes, value.toLowerCase()]
        } else {
          newState = without(prevState.themes, value.toLowerCase())
        }
        return {
          ...prevState,
          themes: newState,
        }
      } else {
        // Handle styles as before
        if (checked) {
          newState = [...prevState.styles, value.toLowerCase()]
        } else {
          newState = without(prevState.styles, value.toLowerCase())
        }
        return {
          ...prevState,
          styles: newState,
        }
      }
    })
  }

  const handleTempoChange = (tempo: number) => {
    setFormState((prevState) => ({
      ...prevState,
      tempo,
    }))
  }

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value.match(/^[0-9]*$/)) return null
    setFormState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }

  const handleMusicTypeChange = (musicType: string) => {
    if (
      (musicType && musicType === "STANDARD") ||
      musicType === "SOUNDEFFECT" ||
      musicType === "FREE"
    ) {
      setFormState((prevState) => ({
        ...prevState,
        musicType: musicType,
        currency: "",
        price: "",
      }))
    }
    if (musicType) {
      setFormState((prevState) => ({
        ...prevState,
        musicType: musicType,
      }))
    }
  }

  const handleAddInstrument = React.useCallback(
    (value: string) => {
      const instruments = clone(insts)
      const instrument = value.toLowerCase()
      instruments.push(instrument)
      setFormState((prevState) => ({
        ...prevState,
        instruments,
      }))
    },
    [insts, setFormState]
  )

  const handleRemoveInstrument = React.useCallback(
    (value: string) => {
      const instruments = clone(insts)
      const instrument = value.toLowerCase()
      pull(instruments, instrument)
      setFormState((prevState) => ({
        ...prevState,
        instruments,
      }))
    },
    [insts, setFormState]
  )

  return (
    <>
      <Typography gutterBottom variant="formTitle">
        <English>Upload Music File</English>
        <Chinese>上傳音樂文件</Chinese>
      </Typography>
      <Grid
        container
        sx={{
          mt: 2,
          mb: 6 + 2, // compensate "& .MuiFormControl-root" margin bottom in ProjectBasicInfo
          "& .dzu-previewFileName": { color: "white", fontSize: 16, fontWeight: "bold" },
          "& .dzu-previewButton": { backgroundColor: "white", padding: 1.5, borderRadius: 9999 },
        }}
      >
        <Dropzone
          accept={acceptMusicFileExt}
          multiple={false}
          maxFiles={1}
          inputContent={
            language === "en" ? "Drag & Drop files here or Browse File" : "拖放文件到此處或瀏覽文件"
          }
          onChangeStatus={handleFileDrop}
          styles={{
            inputLabel: { color: colors.orange, textAlign: "center" },
            dropzone: {
              borderStyle: "dashed",
              borderWidth: 5,
              borderColor: colors.orange,
              alignItems: "center",
            },
            preview: {
              flex: 1,
              borderWidth: 0,
              backgroundColor: alpha(colors.orange, 0.1),
            },
          }}
        />
      </Grid>

      <Typography gutterBottom variant="formTitle">
        <English>Basic Info</English>
        <Chinese>基本資料</Chinese>
      </Typography>
      <UploadSongBasicInfo
        handleUploadImage={handleUploadImage}
        handleSelectChange={handleSelectChange}
        handleTextChange={handleTextChange}
        handleNumberChange={handleNumberChange}
        handleTempoChange={handleTempoChange}
        handleMetreChange={handleMetreChange}
        handleMusicTypeChange={handleMusicTypeChange}
        name={name}
        description={description}
        tempo={tempo}
        metreUpper={metreUpper}
        metreLower={metreLower}
        musicKey={musicKey}
        musicType={musicType}
        currency={currency}
        price={price}
        imagePreviewUrl={imagePreviewUrl}
        setImagePreviewUrl={setImagePreviewUrl}
      />
      <ProjectInstruments
        selectedInstruments={insts}
        selectedStyles={styles}
        selectedMoods={moods}
        selectedThemes={themes}
        handleCheckBoxToggleChange={handleCheckBoxToggleChange}
        handleAddInstrument={handleAddInstrument}
        handleRemoveInstrument={handleRemoveInstrument}
      />
    </>
  )
}
