import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { SubscriptionAPI, SubscriptionPlanAPI } from "../../api"
import SubscriptionPlanCard from "../../component/SubscriptionPlanCard/SubscriptionPlanCard"
import { useLanguage } from "../../context/LanguageProvider"
import Chinese from "../../theme/Chinese"
import { colors } from "../../theme/colors"
import English from "../../theme/English"
import { Tab } from "./tab"

/**
 * This is the subscription page
 */
export default function SubscriptionPage() {
  const { language } = useLanguage()
  const [tab, setTab] = useState<Tab>(Tab.Monthly)
  const [subscribed, setSubscribed] = useState(false)

  const [subscribedPlan, setSubscribedPlan] = useState<string>("")
  const [plans, setPlans] = useState<any[]>([])
  const [error, setError] = useState("")
  const navigate = useNavigate()

  useEffect(() => {
    const checkSubscription = async () => {
      const response = await SubscriptionAPI.checkSubscription()
      if (response?.err) {
        setError(response?.err)
        return
      }
      setSubscribed(response?.res?.success || false)
      setSubscribedPlan(response?.res?.subscription?.planType || "")
    }
    checkSubscription()
  }, [])

  useEffect(() => {
    const getAllPlans = async () => {
      const response = await SubscriptionPlanAPI.getAll()
      if (response?.err) {
        setError(response.err)
        return
      }
      setPlans(response?.res?.plans || [])
    }
    getAllPlans()
  }, [])

  // Filter plans based on selected tab (monthly or yearly)
  const filteredPlans = plans.filter(
    (plan) => plan.billingCycle === (tab === Tab.Monthly ? "monthly" : "yearly")
  )

  return (
    <Paper sx={{ paddingBottom: "15px" }}>
      <Stack justifyContent="center" alignItems="center">
        <Typography variant="h4" align="center" gutterBottom sx={{ padding: 5 }}>
          <>
            <English>Subscription plans</English>
            <Chinese>訂閱方案</Chinese>
          </>
        </Typography>
      </Stack>
      <Box margin="5px" display="flex" justifyContent="center">
        <Button
          sx={{
            textTransform: "none",
            backgroundColor: tab === Tab.Monthly ? colors.orange30 : "",
            color: tab === Tab.Monthly ? "#fff" : "",
            paddingX: 6,
          }}
          variant={tab === Tab.Monthly ? "contained" : "outlined"}
          onClick={() => setTab(Tab.Monthly)}
        >
          <>
            <English>Monthly</English>
            <Chinese>月費計劃</Chinese>
          </>
        </Button>
        <Button
          sx={{
            textTransform: "none",
            backgroundColor: tab === Tab.Annually ? colors.orange30 : "",
            color: tab === Tab.Annually ? "#fff" : "",
            paddingX: 6,
          }}
          variant={tab === Tab.Annually ? "contained" : "outlined"}
          onClick={() => setTab(Tab.Annually)}
        >
          <>
            <English>Annually</English>
            <Chinese>年費計劃</Chinese>
          </>
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 2,
          minHeight: "100vh",
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <SubscriptionPlanCard
              title={language === "zh" ? "免費方案" : "Free"}
              priceText="$0"
              billingCycle={""}
              features={
                language === "zh"
                  ? ["選購任何精選音樂", "無限下載而選購音樂"]
                  : ["Buy any premium music", "Unlimited download the music you’ve bought"]
              }
              buttonText={!subscribed ? "" : language === "zh" ? "現有方案" : "Existing plan"}
              isExistingPlan={!subscribed}
              planType=""
            />
          </Grid>

          {filteredPlans.map((plan) => (
            <Grid item key={plan._id}>
              <SubscriptionPlanCard
                title={language === "en" ? plan.title : plan.title_tc ?? plan.title}
                priceText={`$${plan.price}`}
                billingCycle={
                  language === "zh"
                    ? `${plan.billingCycle === "monthly" ? "月費計劃" : "年費計劃"}`
                    : `Billed ${plan.billingCycle}`
                }
                features={
                  language === "en"
                    ? plan.features
                    : plan.features_tc.length > 0
                    ? plan.features_tc
                    : plan.features
                }
                buttonText={
                  plan.isContactUs
                    ? ""
                    : subscribed && subscribedPlan === plan.planType
                    ? language === "zh"
                      ? "現有方案"
                      : "Existing plan"
                    : subscribedPlan.startsWith("monthly") && plan.planType.startsWith("yearly")
                    ? language === "zh"
                      ? "升級"
                      : "UPGRADE"
                    : subscribedPlan.endsWith("Creator")
                    ? language === "zh"
                      ? "升級"
                      : "UPGRADE"
                    : language === "zh"
                    ? "開始"
                    : "Get started"
                }
                isExistingPlan={subscribed && subscribedPlan === plan.planType}
                planType={plan.planType}
                isContactUs={plan.isContactUs}
                onClick={() => {
                  if (plan.isContactUs) {
                    // Handle "Contact Us" action if needed
                  } else {
                    navigate(`/shoppingCart?subscriptionType=${plan.planType}`)
                  }
                }}
              />
            </Grid>
          ))}
          <Grid item>
            <SubscriptionPlanCard
              title={language === "zh" ? "企業方案" : "Enterprise"}
              priceText={language === "en" ? "Let's talk" : "請聯絡我們"}
              billingCycle=""
              features={
                language === "en"
                  ? [
                      "Unlimited standard songs download",
                      "Monetization for all platforms",
                      "Usage right for all platforms",
                    ]
                  : ["每月無限下載基礎音樂", "作品可於任何平台使用音樂並盈利"]
              }
              buttonText=""
              isExistingPlan={false}
              planType={tab === Tab.Monthly ? "monthlyEnterprise" : "yearlyEnterprise"}
              isContactUs={true}
              onClick={() =>
                navigate(
                  `/shoppingCart/${tab === Tab.Monthly ? "monthlyEnterprise" : "yearlyEnterprise"}`
                )
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
