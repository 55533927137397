import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import CloseIcon from "@mui/icons-material/Close"
import { Box, Button, IconButton, Modal, Typography } from "@mui/material"
import { useLanguage } from "../../../context/LanguageProvider"
import { Dispatch, SetStateAction } from "react"
import { FilterState } from "../../../types/marketPlace"
import { getSortButtonsConfig } from "./config"

/**
 * Filter menu in market place
 */
export default function MobileMarketPlaceSortMenu({
  open,
  onClose,
  filter,
  setFilter,
}: {
  open: boolean
  onClose: () => void
  filter: FilterState
  setFilter: Dispatch<SetStateAction<FilterState>>
}) {
  const { language } = useLanguage()
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
          <IconButton edge="end" onClick={() => onClose()} aria-label="close filtering modal">
            <CloseIcon />
          </IconButton>
        </Box>

        <Box>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Sort by
          </Typography>

          <Box>
            {getSortButtonsConfig(language)?.map(({ type, label, key }) => {
              const isCurrentSortKey = filter?.sortBy === key
              return (
                <div key={`sort_${type}`}>
                  <Button
                    size="large"
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                      minWidth: "100%",
                      py: 1,
                      mb: 3,
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      const newSortOrder = filter?.sortOrder === "DESC" ? "ASC" : "DESC"
                      setFilter((prevFilter) => {
                        return { ...prevFilter, sortBy: key, sortOrder: newSortOrder }
                      })
                      onClose()
                    }}
                  >
                    {label}
                    {!isCurrentSortKey ? (
                      <></>
                    ) : filter?.sortOrder === "DESC" ? (
                      <ArrowDropDownIcon />
                    ) : (
                      <ArrowDropUpIcon />
                    )}
                  </Button>
                </div>
              )
            })}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  px: 4,
  pt: 2,
  pb: 4,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "scroll",
  // border: "2px solid #000",
}
