import { FormGroup, FormControlLabel, Checkbox } from "@mui/material"
import { useLanguage } from "context/LanguageProvider"

interface AgreementProps {
  checked: boolean
  error: boolean
  onChange: () => void
}

/**
 * AgreementCheckBox component renders a checkbox with a label for agreeing to terms.
 */
export default function AgreementCheckBox({ checked, onChange, error }: AgreementProps) {
  const { language } = useLanguage()
  return (
    <FormGroup>
      <FormControlLabel
        sx={{ color: error ? "red" : "white" }}
        onChange={onChange}
        control={<Checkbox value={checked} checked={checked} data-testid="user-form-agreement" />}
        label={
          language === "en"
            ? "Agree with our Terms of Use, Privacy Policy, and Cookies Policy. You may receive notification from us periodically and you may opt out at anytime."
            : "同意我們的使用條款、隱私政策和Cookie政策。您可能會定期收到我們的通知，您可以隨時選擇退出。"
        }
      />
    </FormGroup>
  )
}
