import React, { useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  Box,
} from "@mui/material"
import FacebookIcon from "@mui/icons-material/Facebook"
import TwitterIcon from "@mui/icons-material/Twitter"
import WhatsAppIcon from "@mui/icons-material/WhatsApp"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import LinkIcon from "@mui/icons-material/Link"
import EmailIcon from "@mui/icons-material/Email"
import CloseIcon from "@mui/icons-material/Close"

import { ShareAPI } from "../../api"
import English from "../../theme/English"
import Chinese from "../../theme/Chinese"

export interface ShareToPopUpProps {
  open: boolean
  onClose: () => void
  musicId: string
  musicName: string
}

/**
 ShareToPopUp component
 */
export default function ShareToPopUp({ open, onClose, musicId, musicName }: ShareToPopUpProps) {
  const handleShareToFacebook = async (musicId: string) => {
    try {
      const response = await ShareAPI.shareToFacebook(musicId)
      if (response?.err) {
        console.error("Failed to share to facebook")
        return
      }
      console.log("Item shared to facebook:", response)
    } catch (err) {
      console.error("Failed to share to facebook", err)
    }
  }

  const handleShareToTwitter = async (musicId: string, name: string) => {
    try {
      const response = await ShareAPI.shareToTwitter(musicId, name)
      if (response?.err) {
        console.error("Failed to share to twitter")
        return
      }
      console.log("Item shared to twitter:", response)
    } catch (err) {
      console.error("Failed to share to twitter", err)
    }
  }

  const handleShareToWhatsApp = async (musicId: string, name: string) => {
    try {
      const response = await ShareAPI.shareToWhatsApp(musicId, name)
      if (response?.err) {
        console.error("Failed to share to whatsapp")
        return
      }
      console.log("Item shared to whatsapp:", response)
    } catch (err) {
      console.error("Failed to share to whatsapp", err)
    }
  }

  const handleShareToLinkedIn = async (musicId: string, name: string) => {
    try {
      const response = await ShareAPI.shareToLinkedIn(musicId, name)
      if (response?.err) {
        console.error("Failed to share to linkedin")
        return
      }
      console.log("Item shared to linkedin:", response)
    } catch (err) {
      console.error("Failed to share to linkedin", err)
    }
  }

  const handleShareByEmail = async (musicId: string, name: string) => {
    try {
      const response = await ShareAPI.shareToEmail(musicId, name)
      if (response?.err) {
        console.error("Failed to share by email")
        return
      }
      console.log("Item shared by email:", response)
    } catch (err) {
      console.error("Failed to share by email", err)
    }
  }

  const handleCopyLink = async (musicId: string) => {
    try {
      const response = await ShareAPI.copyLink(musicId)
      if (response?.err) {
        console.error("Failed to copy link")
        return
      }
      console.log("Link copied:", response)
    } catch (err) {
      console.error("Failed to copy link", err)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            <English>Share to ...</English>
            <Chinese>分享至 ...</Chinese>
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <IconButton aria-label="Facebook" onClick={() => handleShareToFacebook(musicId)}>
                <FacebookIcon fontSize="large" />
              </IconButton>
              <Typography align="center">
                <English>Facebook</English>
                <Chinese>臉書</Chinese>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <IconButton
                onClick={() => handleShareToTwitter(musicId, musicName)}
                aria-label="Twitter"
              >
                <TwitterIcon fontSize="large" />
              </IconButton>
              <Typography align="center">
                <English>Twitter</English>
                <Chinese>推特</Chinese>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <IconButton
                onClick={() => handleShareToWhatsApp(musicId, musicName)}
                aria-label="WhatsApp"
              >
                <WhatsAppIcon fontSize="large" />
              </IconButton>
              <Typography align="center">
                <English>WhatsApp</English>
                <Chinese>WhatsApp</Chinese>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <IconButton
                onClick={() => handleShareToLinkedIn(musicId, musicName)}
                aria-label="LinkedIn"
              >
                <LinkedInIcon fontSize="large" />
              </IconButton>
              <Typography align="center">
                <English>LinkedIn</English>
                <Chinese>領英</Chinese>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <IconButton onClick={() => handleCopyLink(musicId)} aria-label="CopyLink">
                <LinkIcon fontSize="large" />
              </IconButton>
              <Typography align="center">
                <English>Copy Link</English>
                <Chinese>複製連結</Chinese>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <IconButton onClick={() => handleShareByEmail(musicId, musicName)} aria-label="Email">
                <EmailIcon fontSize="large" />
              </IconButton>
              <Typography align="center">
                <English>Email</English>
                <Chinese>電子郵件</Chinese>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
