import _ from "lodash"
import React from "react"
import { useNavigate } from "react-router-dom"
import { ProjectAPI } from "../../api"
import { Page, PageContainer, PageTitle } from "../../component"
import ProjectForm from "../../component/ProjectForm/ProjectForm"
import { useNotification } from "../../context/NotificationProvider"
import { ProjectFormStateProps } from "../../types"
import { ProjectFormInitiateState } from "./projectData"
import ProjectPreview from "./ProjectPreview"
import ProjectStepper from "./ProjectStepper"
// import Info from "../ProjectDetail/Info"
import { useLanguage } from "../../context/LanguageProvider"
import UploadSongInfo from "../../pages/UploadSong/UploadSongInfo"

/**
 * The start project page that provides a form to create a project
 * Reuse UploadSongInfo component for start project info. preview
 */
export default function StartProject() {
  const navigate = useNavigate()
  const { openNotification } = useNotification()
  const { language } = useLanguage()
  const steps = ["1", "2"]

  const [projectForm, setProjectForm] =
    React.useState<ProjectFormStateProps>(ProjectFormInitiateState)
  const [uploadImage, setUploadImage] = React.useState<File | null>(null)
  const [agreement, setAgreement] = React.useState(false)

  const handleUploadImage = (file: File | null) => setUploadImage(file)

  const handleFormSubmit = async () => {
    const { imageUrl, ...rest } = projectForm // Omitting imageUrl as thats only a base64 url for preview
    const { res, error } = await ProjectAPI.createProject(rest)

    if (error) openNotification("ERROR", error.message)

    if (uploadImage) await ProjectAPI.uploadProjectImage(res?._id, uploadImage)

    location.assign(`/projects/${res?._id}`)
  }

  const onCancel = () => navigate("/projects")

  // Check form validation
  const musicTypeCheck = (musicType: string) => {
    if (musicType === "STANDARD") return true
    if (musicType === "SOUNDEFFECT") return true
    if (musicType === "FREE") return true
    if (musicType === "PREMIUM")
      return Boolean(projectForm?.currency) && Boolean(projectForm?.price)
    return false
  }
  const extraMusicTypeValidation = musicTypeCheck(projectForm?.musicType)

  const isFormValid =
    Boolean(projectForm?.name) &&
    Boolean(projectForm?.description) &&
    Boolean(!_.isEmpty(projectForm?.instruments)) &&
    Boolean(!_.isEmpty(projectForm?.styles)) &&
    Boolean(projectForm?.tempo) &&
    Boolean(projectForm?.musicKey) &&
    Boolean(projectForm?.metreUpper) &&
    Boolean(projectForm?.metreLower) &&
    extraMusicTypeValidation

  return (
    <Page pageId={StartProject} noPadding>
      <PageContainer>
        <PageTitle
          title={language === "en" ? "Start Your Project" : "開始你的計劃"}
          sx={{ textAlign: "center", my: 4 }}
        />
        <ProjectStepper
          steps={steps}
          isFormValid={isFormValid}
          isAgreementValid={agreement}
          onSubmit={handleFormSubmit}
          onCancel={onCancel}
          components={[
            <ProjectForm
              key={1}
              onSubmit={() => {}}
              setFormState={setProjectForm}
              formState={projectForm}
              handleUploadImage={handleUploadImage}
            />,
            <ProjectPreview
              key={2}
              onAgreementChange={() => setAgreement(!agreement)}
              agreement={agreement}
            >
              {/* <Info {...projectForm} /> */}
              <UploadSongInfo
                {...projectForm}
                moods={[]}
                themes={[]}
                imagePreviewUrl={projectForm?.imageUrl as string}
              />
            </ProjectPreview>,
          ]}
        />
      </PageContainer>
    </Page>
  )
}
