import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline"
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline"
import VolumeDown from "@mui/icons-material/VolumeDown"
import VolumeUp from "@mui/icons-material/VolumeUp"
import { Box, IconButton, Slider, Stack, Typography } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { streamRequest } from "../../api/request"
import WaveMusicPlayer from "../../component/WaveMusicPlayer/WaveMusicPlayer"
import { useMusicPlayer } from "../../context"
import useMUIMediaQuery from "../../hooks/useMUIMedia"
import { colors } from "../../theme/colors"
import { createStreamingUrl } from "../../util"

/** 
Footer Music Player
*/
export default function FooterMusicPlayer() {
  const {
    currentSong,
    volume,
    setVolume,
    duration,
    setDuration,
    currentTime,
    setCurrentTime,
    isPlaying,
    playSong,
    stopSong,
  } = useMusicPlayer()

  const [audioUrl, setAudioUrl] = useState<string | null>(null)

  // Get streaming URL when currentSong changes
  useEffect(() => {
    if (currentSong) {
      const fetchAudio = async () => {
        try {
          const streamUrl = createStreamingUrl(currentSong._id)
          const audioBlob = await streamRequest({ url: streamUrl })
          setAudioUrl(audioBlob)
        } catch (error) {
          console.error("Failed to load audio:", error)
        }
      }

      fetchAudio()
    } else {
      setAudioUrl(null)
    }
  }, [currentSong])

  const name = currentSong?.projectName

  // Using the MUI media query for responsiveness
  const { isMobile } = useMUIMediaQuery()

  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    const volumeValue = newValue as number
    setVolume(volumeValue)
  }

  const timeToString = (time: number) => (time >= 10 ? `${time}` : `0${time}`)

  const time = useMemo(() => {
    const min = Math.floor(duration / 60)
    const secRemain = Math.floor(duration % 60)
    return `${timeToString(min)}:${timeToString(secRemain)}`
  }, [duration])

  const playedTime = useMemo(() => {
    const min = Math.floor(currentTime / 60)
    const secRemain = Math.floor(currentTime % 60)
    return `${timeToString(min)}:${timeToString(secRemain)}`
  }, [currentTime])

  const scrollingTextStyle = useMemo(
    () => ({
      whiteSpace: "nowrap",
      display: "inline-block",
      width: "200px",
      animation: isPlaying ? "scroll 5s linear infinite" : "none",
      "@keyframes scroll": {
        "0%": {
          transform: "translateX(120%)",
        },
        "100%": {
          transform: "translateX(-120%)",
        },
      },
    }),
    [isPlaying]
  )

  return (
    <Stack
      spacing={2}
      direction={isMobile ? "column" : "row"}
      sx={{
        position: "fixed", // Makes it sticky
        bottom: 0, // Sticks to the bottom
        left: 0, // Full width from left to right
        width: "100%",
        zIndex: 1000, // Ensures it stays on top
        boxShadow: "0 -2px 10px rgba(0,0,0,0.2)",
        bgcolor: colors.darkGrey2,
        padding: isMobile ? 0 : 1,
        overflow: "hidden",
        opacity: currentSong ? 1 : 0,
        transition: "opacity 0.5s",
        maxHeight: currentSong ? "100%" : 0,
      }}
      alignItems="center"
      bgcolor={colors.darkGrey2}
      padding={1}
    >
      <Box sx={{ overflow: "hidden" }}>
        <Typography sx={scrollingTextStyle}>{name}</Typography>
      </Box>
      <Stack direction="row" flex="3" alignItems="center">
        {isPlaying ? (
          <IconButton aria-label="pause-button" onClick={() => stopSong()}>
            <PauseCircleOutlineIcon />
          </IconButton>
        ) : (
          <IconButton
            aria-label="play-button"
            onClick={() => {
              if (currentSong) {
                playSong(currentSong)
              }
            }}
          >
            <PlayCircleOutlineIcon />
          </IconButton>
        )}
        <Typography sx={{ minWidth: "50px", textAlign: "center" }}>{playedTime}</Typography>
        {audioUrl && (
          <WaveMusicPlayer
            key={audioUrl}
            audioUrl={audioUrl}
            playingAudio={isPlaying}
            waveformHeight={20}
            setDuration={setDuration}
            setCurrentTime={setCurrentTime}
            volume={volume}
          />
        )}
        <Typography>{time}</Typography>
      </Stack>

      {/* Conditionally rendering the volume controls on mobile */}
      {!isMobile && (
        <Stack direction="row" spacing={1} alignItems="center">
          <IconButton
            aria-label="volume-down-button"
            onClick={() => setVolume(volume - 0.05 < 0 ? 0 : volume - 0.05)}
          >
            <VolumeDown />
          </IconButton>
          <Slider
            value={volume}
            onChange={handleVolumeChange}
            aria-labelledby="continuous-slider"
            min={0}
            max={1}
            step={0.01}
            sx={{ width: "150px" }}
          />
          <IconButton
            aria-label="volume-up-button"
            onClick={() => setVolume(volume + 0.05 > 1 ? 1 : volume + 0.05)}
          >
            <VolumeUp />
          </IconButton>
        </Stack>
      )}
    </Stack>
  )
}
