import { Box, Button, CircularProgress, Divider, SxProps, Theme } from "@mui/material"
import { useLanguage } from "../../context/LanguageProvider"

import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart"

const BUTTON_WIDTH = 150
const BUTTON_BORDER_RADIUS = 16

interface BuyNowOrAddToCartButtonProps {
  sx?: SxProps<Theme>
  onBuyNowClick?: () => void
  onAddToCartClick?: () => void
  isLoading?: boolean
}
/**
 * Market Place Buy Now Or Add To Cart Button
 */
export default function BuyNowOrAddToCartButton({
  sx,
  onBuyNowClick,
  onAddToCartClick,
  isLoading = false,
}: BuyNowOrAddToCartButtonProps) {
  const { language } = useLanguage()

  return (
    <Box
      sx={{
        ...sx,
        width: BUTTON_WIDTH,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: BUTTON_BORDER_RADIUS,
        overflow: "hidden",
      }}
    >
      <Button
        sx={{ flex: 1, borderRadius: 0, py: 1 }}
        onClick={onBuyNowClick}
        variant="text"
        disabled={isLoading}
        startIcon={isLoading ? <CircularProgress size={16} color="primary" /> : null}
      >
        {isLoading
          ? language === "zh"
            ? "處理中..."
            : "Processing..."
          : language === "zh"
          ? "立即購買"
          : "Buy Now"}
      </Button>
      <Divider orientation="vertical" flexItem />
      <Button
        sx={{ flex: 1, borderRadius: 0, py: 1 }}
        onClick={onAddToCartClick}
        variant="text"
        disabled={isLoading}
      >
        <AddShoppingCartIcon fontSize="small" />
      </Button>
    </Box>
  )
}
