export const SORT_BUTTONS_CONFIG = [
  { type: "latest", label: "The latest", key: "uploadTimestamp" },
  // { type: "popular", label: "Most popular", key: "tbc1" },
  // { type: "follow", label: "Following", key: "tbc2" },
  // { type: "favorite", label: "Favorited", key: "tbc3" },
]

const translations: { [key: string]: string } = {
  latest: "最新",
  popular: "最受歡迎",
  follow: "追蹤中",
  favorite: "已收藏",
}

export const getSortButtonsConfig = (language: "en" | "zh") => {
  return SORT_BUTTONS_CONFIG.map((btn) => ({
    ...btn,
    label: language === "zh" ? translations[btn.type] || btn.label : btn.label,
  }))
}
