export const translateTheme = (theme: string, language: "en" | "zh"): string => {
  const translations: { [key: string]: string } = {
    action: "動作",
    comedy: "喜劇",
    corporate: "企業",
    documentary: "紀錄片",
    fantasy: "奇幻",
    food: "美食",
    future: "未來",
    gaming: "遊戲",
    lifestyle: "生活方式",
    nature: "自然",
    party: "派對",
    romance: "浪漫",
    "sci-fi": "科幻",
    science: "科學",
    travel: "旅行",
    weddings: "婚禮",
  }

  if (language === "zh") {
    return translations[theme.toLowerCase()] || theme
  } else {
    return theme.charAt(0).toUpperCase() + theme.slice(1)
  }
}
