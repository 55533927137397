import { Box, CircularProgress, Grid, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { SavedMusicAPI } from "../../api"
import MarketPlaceTable from "../../component/MarketPlace/Table/MarketPlaceTable"
import Chinese from "../../theme/Chinese"
import English from "../../theme/English"

/**
 * Saved Music Tab
 */
export default function SavedMusic() {
  const [savedMusics, setSavedMusics] = React.useState([])
  const [error, setError] = React.useState("")
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getSavedMusics = async () => {
      setIsLoading(true)
      try {
        const response = await SavedMusicAPI.getSavedMusic()
        if (response?.err) {
          setError(response?.err)
          setSavedMusics([])
          return
        }
        setSavedMusics(response?.res || [])
      } catch (err) {
        console.error("Failed to fetch saved music:", err)
        setSavedMusics([])
      } finally {
        setIsLoading(false)
      }
    }
    getSavedMusics()
  }, [])

  console.log(savedMusics)

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return (
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Typography color="error">
          <English>Error loading saved music: {error}</English>
          <Chinese>加載收藏音樂時出錯: {error}</Chinese>
        </Typography>
      </Box>
    )
  }

  if (savedMusics.length === 0) {
    return (
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Typography>
          <English>You have not saved any music yet</English>
          <Chinese>您尚未收藏任何音樂</Chinese>
        </Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container columnSpacing={2}>
        <Grid item xs={10}>
          <MarketPlaceTable data={savedMusics?.map((t: any) => t.musicId)} />
        </Grid>
      </Grid>
    </Box>
  )
}
