import { getRequest, postRequest } from "./request"

const Project: any = {}

Project.getAllPublicProject = async (searchQuery?: string) => {
  let url = "/project"
  if (searchQuery) url += `?${searchQuery}`
  return getRequest({ url })
}

Project.getProject = async (projectId: string) => {
  return getRequest({ url: `/project/${projectId}` })
}

Project.createProject = async (data: any) => {
  const params = {
    method: "POST",
  }
  return postRequest({
    url: `/project`,
    params,
    data,
  })
}

Project.updateInfo = async (projectId: string, rawData: any) => {
  const params = {
    method: "PUT",
  }
  const data = {
    ...rawData,
  }
  return postRequest({
    url: `/project/${projectId}`,
    params,
    data,
  })
}

Project.uploadProjectImage = async (projectId: string, image: File) => {
  const data = new FormData()
  data.append("file", image)

  return postRequest({
    url: `/project/file/project_image/${projectId}`,
    data,
  })
}

Project.createComment = async (projectId: string, rawData: any) => {
  const data = {
    ...rawData,
  }
  return postRequest({
    url: `/project/${projectId}/comment`,
    data,
  })
}

Project.requestToJoin = async (projectId: string, rawData: any) => {
  const data = {
    ...rawData,
  }
  return postRequest({
    url: `/project/${projectId}/request`,
    data,
  })
}

Project.followProject = async (projectId: string) => {
  const params = {
    method: "PUT",
  }
  return postRequest({
    url: `/project/${projectId}/follow`,
    params,
  })
}

Project.unfollowProject = async (projectId: string) => {
  const params = {
    method: "PUT",
  }
  return postRequest({
    url: `/project/${projectId}/unfollow`,
    params,
  })
}

Project.approveUserToProject = async (projectId: string, userId: string, instrument: string) => {
  const data = {
    userId,
    instrument,
  }
  return postRequest({
    url: `/project/${projectId}/request/approve`,
    data,
  })
}

Project.denyUserToProject = async (projectId: string, userId: string, instrument: string) => {
  const data = {
    userId,
    instrument,
  }

  return postRequest({
    url: `/project/${projectId}/request/deny`,
    data,
  })
}

Project.uploadInstrumentSheet = async (projectId: string, instrument: string, file: File) => {
  const data = new FormData()
  data.append("file", file)

  return postRequest({
    url: `/project/file/music_sheet/${projectId}?instrument=${encodeURIComponent(instrument)}`,
    data,
  })
}

Project.uploadInstrumentMusic = async (projectId: string, instrument: string, file: File) => {
  const data = new FormData()
  data.append("file", file)

  return postRequest({
    url: `/project/file/music/${projectId}?instrument=${encodeURIComponent(instrument)}`,
    data,
  })
}

Project.deleteMusicFile = async (projectId: string, fileId: string) => {
  const params = {
    method: "DELETE",
  }
  return postRequest({
    url: `/project/file/music/${projectId}/${fileId}`,
    params,
  })
}

Project.finishProject = async (projectId: string, file: File) => {
  const data = new FormData()
  data.append("file", file)

  return postRequest({
    url: `/project/${projectId}/finish`,
    data,
  })
}
Project.uploadSong = async (projectId: string, file: File) => {
  const data = new FormData()
  data.append("file", file)

  return postRequest({
    url: `/project/${projectId}/uploadSong`,
    data,
  })
}

Project.inviteUser = async (projectId: string, rawData: any) => {
  const data = {
    ...rawData,
  }
  return postRequest({
    url: `/project/${projectId}/invite`,
    data,
  })
}

export default Project
