/* eslint-disable react/no-multi-comp */
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { Box, Button, Divider, Grid, Link, Typography } from "@mui/material"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import React, { useEffect, useState } from "react"
import { SubscriptionAPI } from "../../api"
import {
  FourthBannerEn,
  FourthBannerZh,
  MainBannerEn,
  MainBannerZh,
  SecondBannerEn,
  SecondBannerZh,
  ThirdBannerEn,
  ThirdBannerZh,
} from "../../assets/carousel"
import { Page, PageContainer, SwipeableListGroup } from "../../component"
import { useLanguage } from "../../context/LanguageProvider"
import { useCheckIsAdmin } from "../../hooks/useCheckIsAdmin"
import Chinese from "../../theme/Chinese"
import { colors } from "../../theme/colors"
import English from "../../theme/English"
import All from "./tab/All"
import Premium from "./tab/Premium"
import SoundEffect from "./tab/SoundEffect"
import Standard from "./tab/Standard"
import useMUIMediaQuery from "../../hooks/useMUIMedia"
import Free from "./tab/Free"

type MarketPlaceTab = "all" | "premium" | "standard" | "soundEffect" | "free"

const CAROUSEL_ITEMS_ZH = [
  {
    label: "Music Creation No Limit",
    imageSrc: MainBannerZh,
  },
  {
    label: "The right music for every story",
    imageSrc: SecondBannerZh,
  },
  {
    label: "Job center coming soon",
    imageSrc: ThirdBannerZh,
  },
  {
    label: "Upload and sell your royalty free music",
    imageSrc: FourthBannerZh,
  },
]

const CAROUSEL_ITEMS_EN = [
  {
    label: "Music Creation No Limit",
    imageSrc: MainBannerEn,
  },
  {
    label: "The right music for every story",
    imageSrc: SecondBannerEn,
  },
  {
    label: "Job center coming soon",
    imageSrc: ThirdBannerEn,
  },
  {
    label: "Upload and sell your royalty free music",
    imageSrc: FourthBannerEn,
  },
]

const MARKETPLACE_TABS = [
  {
    value: "all",
    label: (
      <>
        <English>All</English>
        <Chinese>全部</Chinese>
      </>
    ),
  },
  {
    value: "premium",
    label: (
      <>
        <English>Premium</English>
        <Chinese>精選</Chinese>
      </>
    ),
  },
  {
    value: "standard",
    label: (
      <>
        <English>Standard</English>
        <Chinese>基礎</Chinese>
      </>
    ),
  },
  {
    value: "soundEffect",
    label: (
      <>
        <English>Sound Effect</English>
        <Chinese>音效</Chinese>
      </>
    ),
  },
  {
    value: "free",
    label: (
      <>
        <English>Free</English>
        <Chinese>免費</Chinese>
      </>
    ),
  },
] as const

/**
 * Market place page
 */
export default function MarketPlacePage() {
  const { isMobile } = useMUIMediaQuery()
  const { isAdmin } = useCheckIsAdmin()
  const { language } = useLanguage()

  const [activeTab, setActiveTab] = useState<MarketPlaceTab>("all")
  const [showMessage, setShowMessage] = useState(false) // Control message visibility
  const [subscribed, setSubscribed] = useState(true)
  useEffect(() => {
    const checkSubscription = async () => {
      const response = await SubscriptionAPI.checkSubscription()
      if (response?.err) {
        return
      }
      setSubscribed(response?.res.success)
    }
    checkSubscription()
  }, [])

  useEffect(() => {
    if (!subscribed) {
      setShowMessage(true)
    }
  }, [subscribed])
  const handleDismiss = () => {
    setShowMessage(false)
  }

  const CAROUSEL_ITEMS = language === "en" ? CAROUSEL_ITEMS_EN : CAROUSEL_ITEMS_ZH

  const PageContent = () => {
    return (
      <>
        <Grid item xs={12} alignItems={"center"}>
          <SwipeableListGroup items={CAROUSEL_ITEMS} />
        </Grid>

        <Tabs
          value={activeTab}
          onChange={(event: React.SyntheticEvent, tab: MarketPlaceTab) => setActiveTab(tab)}
          textColor="primary"
          indicatorColor="primary"
        >
          {MARKETPLACE_TABS.map(({ value, label }) => {
            if (!isAdmin && value === "premium") return null
            return <Tab key={value} value={value} label={label} sx={{ textTransform: "none" }} />
          })}
        </Tabs>

        <Divider sx={{ mb: 2 }} />

        {activeTab === "all" && <All />}
        {isAdmin && activeTab === "premium" && <Premium />}
        {activeTab === "standard" && <Standard />}
        {activeTab === "soundEffect" && <SoundEffect />}
        {activeTab === "free" && <Free />}
      </>
    )
  }

  return (
    <div>
      <Page pageId={MarketPlacePage} noPadding>
        {isMobile ? (
          <PageContainer>
            <PageContent />
          </PageContainer>
        ) : (
          <Box py={3} px={3}>
            <PageContent />
          </Box>
        )}
      </Page>

      {showMessage && !isAdmin && (
        <Box
          sx={{
            position: "fixed",
            bottom: 60,
            right: 10,
            width: isMobile ? "90%" : "350px",
            padding: 2,
            backgroundColor: colors.grey3,
            boxShadow: 3,
            borderRadius: 4,
            zIndex: 1000,
            textAlign: "center",
          }}
        >
          <Typography variant="body2" sx={{ marginBottom: 2 }}>
            <English>Subscribe now to download unlimited songs without a watermark!</English>
            <Chinese>立即訂閱，下載無限無水印歌曲！</Chinese>
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            component={Link}
            href="/subscription"
            sx={{ textDecoration: "none", marginBottom: 1 }}
          >
            <English>Subscribe Now</English>
            <Chinese>立即訂閱</Chinese>
          </Button>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            sx={{ textDecoration: "none" }}
            onClick={handleDismiss}
          >
            <English>Maybe later</English>
            <Chinese>也許稍後</Chinese>
          </Button>
        </Box>
      )}

      {/* Admin Upload Button */}
      {isAdmin && (
        <Button
          component={Link}
          href="/uploadSong"
          type="submit"
          color={"primary"}
          variant="contained"
          sx={{
            position: "fixed",
            right: "0%",
            bottom: "0%",
            borderRadius: 30,
            py: 1.5,
            mr: 3,
            mb: 10,
            zIndex: 999,
          }}
          endIcon={<ArrowForwardIcon />}
        >
          <English>Upload</English>
          <Chinese>上傳</Chinese>
        </Button>
      )}
    </div>
  )
}
