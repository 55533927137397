import { Grid, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React, { useMemo } from "react"
import { recordingLevels as recordingLevels_en } from "../../../component/UserForm/user"
import { useLanguage } from "../../../context/LanguageProvider"

interface RecordingLevelProps {
  handleChangeEducation: (_: React.MouseEvent<HTMLElement, MouseEvent>, value: number) => void
  recordingLevel: number
}

function CustomToggleButton(props: any) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("lg"))
  return (
    <>
      <Grid item xs={12}>
        <ToggleButton {...props} fullWidth />
        <Typography variant="body1" sx={{ color: "#BDBDBD", mb: 2 }}>
          {props.description}
        </Typography>
      </Grid>
      {matches && <Grid item sm={12} lg={1}></Grid>}
    </>
  )
}

// eslint-disable-next-line react/no-multi-comp
function RecordingLevel({ handleChangeEducation, recordingLevel }: RecordingLevelProps) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("lg"))
  const { language } = useLanguage()

  const recordingLevels = useMemo(
    () =>
      language === "zh"
        ? [
            {
              label: "初學者",
              description: "沒有或只有少量錄音經驗，錄音硬件和/或軟件",
            },
            {
              label: "半專業",
              description: "有一些自我錄音經驗，但是對錄音軌道新手",
            },
            {
              label: "專業",
              description: "在自我錄音和編輯錄音軌道方面有豐富的經驗",
            },
          ]
        : recordingLevels_en,
    [language]
  )

  return (
    <ToggleButtonGroup
      color="primary"
      orientation={matches ? "horizontal" : "vertical"}
      value={recordingLevel}
      exclusive
      onChange={handleChangeEducation}
      data-testid="mp-recordingLevels"
    >
      {recordingLevels.map(({ description, label }, index) => (
        <CustomToggleButton key={label} value={index + 1} description={description}>
          {label}
        </CustomToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

export default React.memo(RecordingLevel, (prevProps, nextProps) => {
  return prevProps.recordingLevel === nextProps.recordingLevel
})
