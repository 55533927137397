import { uniq } from "lodash"
import moment from "moment"
import {
  language,
  musicStyle,
  recordingSoftware,
  instrumentsList as instruments,
  mood,
  theme,
  // @ts-ignore
} from "@womucon/share"
import { Validations } from "../../hooks/useFormValidation"
import { InstrumentExperienceProps, UserFormStateProps } from "../../types"

export const instrumentsList: string[] = uniq(instruments)
export const recordingSoftwares = recordingSoftware.sort((a: string, b: string) =>
  a.localeCompare(b)
)
export const musicStyles = uniq(musicStyle.sort((a: string, b: string) => a.localeCompare(b)))
export const moodList = mood
export const themeList = theme
export const languages = language
  .filter((m: string) => Boolean(m))
  .sort((a: string, b: string) => a.localeCompare(b))

export const educations = [
  "High School",
  "Associate",
  "Bachelor",
  "Master",
  "Doctoral",
  "None of the Above",
]
export const recordingLevels = [
  {
    label: "Beginner",
    description: "None to only few experience with recording, recoding hardware and/or software",
  },
  {
    label: "Semi-Professional",
    description: "Some self recording experience, but new to recording track",
  },
  {
    label: "Professional",
    description: "Abundance experience in self recording, and editing record track",
  },
]

export const MIN_DATE = moment().subtract(14, "years")
export const MIN_AGE = MIN_DATE.calendar()
export const MIN_YEAR = MIN_DATE.format("YYYY")
export const MIN_MONTH = MIN_DATE.format("MM")

export const UserFormInitiateState: UserFormStateProps = {
  role: "USER",
  type: "MUSICIAN",
  name: "",
  legalName: "",
  email: "",
  googleId: "",
  profileImageUrl: "none",
  profileImage: {
    downloadUrl: "",
  },
  description: "",
  instruments: [],
  language: ["english"],
  nationality: "US",
  styleOfInterest: [],
  recordingSoftware: [],
  address: {
    country: "US",
  },
  phone: "",
  recordingLevel: 1,
  isUserFormEditable: false, // Passed down from User toggle between allow and disallow editing
  creatingUser: false, // Conditionally display fields that are unique to creating user (agreement)
  agreement: {
    version: "v1",
    agree: false,
  },
  musicEducation: "High School",
  musicCertificate: "",
  dateOfBirth: null,
  dobYear: MIN_YEAR,
  dobMonth: MIN_MONTH,
  password: "",
}

export const userFormValidation: Validations<UserFormStateProps> = {
  name: {
    validate: {
      isValid: (name: string) => name.length > 2,
      message: "Please enter a valid name",
    },
  },
  password: {
    validate: {
      isValid: (password: string) => password?.length > 2,
      message: "Please enter a valid password",
    },
  },
  agreement: {
    validate: {
      isValid: ({ agree }: { agree: boolean }) => agree,
      message: "Please agree to our term of use",
    },
  },
  dateOfBirth: {
    validate: {
      isValid: (dob: Date) => {
        const today = moment()
        return today.diff(dob, "year") > 13
      },
      message: "You must be at least 13 years old to register",
    },
  },
}
