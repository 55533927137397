import { Box, Button, Stack, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import Chinese from "../../theme/Chinese"
import English from "../../theme/English"
import { colors } from "../../theme/colors"

/**
 *Subscribe Section
 */
export default function SubscribeSection() {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        py: 4,
        px: 2,
        textAlign: "center",
        borderRadius: 2,

        backgroundColor: colors.darkGrey2,
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
        <English>Got this asset for free & unlimited download for 1 month</English>
        <Chinese>免費下載音樂一個月</Chinese>
      </Typography>

      <Typography variant="body1" sx={{ mb: 3 }}>
        <English>Subscribe to the monthly plan and you get unlimited download tracks</English>
        <Chinese>訂閱我們的方案，無限下載音樂</Chinese>
      </Typography>

      <Stack direction="row" spacing={2} justifyContent="center">
        <Button variant="outlined" sx={{ px: 6 }} onClick={() => navigate("/subscription")}>
          <English>MORE PLANS</English>
          <Chinese>更多方案</Chinese>
        </Button>
        <Button
          variant="contained"
          sx={{ px: 6 }}
          onClick={() => navigate("/shoppingCart?subscriptionType=yearlyStandard")}
        >
          <English>SUBSCRIBE</English>
          <Chinese>訂閱</Chinese>
        </Button>
      </Stack>
    </Box>
  )
}
