import CloseIcon from "@mui/icons-material/Close"
import NotificationAddOutlinedIcon from "@mui/icons-material/NotificationAddOutlined"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import { Badge, Box, Button, CircularProgress, Divider, MenuItem, Typography } from "@mui/material"
import Avatar from "@mui/material/Avatar"
import IconButton from "@mui/material/IconButton"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import * as React from "react"
import { useEffect } from "react"
import { Link, NavLink } from "react-router-dom"
import WMC_LOGO from "../../assets/brands/logo-sq.png"
import { useCart } from "../../context/CartProvider"
import { useUserInfo } from "../../context/UserInfoProvider"
import useMUIMediaQuery from "../../hooks/useMUIMedia"
import Chinese from "../../theme/Chinese"
import English from "../../theme/English"
import { createDownloadUrl, logOut } from "../../util"
import NotificationtList from "./NotificationList"
import LanguageToggle from "./LanguageToggle"

/**
 * AppBarMenu
 */
export default function AppBarMenu() {
  const { isMobile } = useMUIMediaQuery()
  const iconButtonSize = isMobile ? "small" : "large"
  const [cartFetched, setCartFetched] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [anchorNotificationEl, setAnchorNotificationEl] = React.useState<null | HTMLElement>(null)
  const [anchorShoppingCartEl, setAnchorShoppingCartEl] = React.useState<null | HTMLElement>(null)

  const { userInfo, userNotification, updateUserNotification } = useUserInfo()
  const { cartItems, fetchCartItems, removeFromCart, removingItemId } = useCart() // Updated to include removingItemId

  useEffect(() => {
    if (!cartFetched) {
      fetchCartItems()
      setCartFetched(true)
    }
  }, [cartFetched, fetchCartItems])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = () => {
    logOut()
  }

  if (!userInfo) {
    return null
  }

  const { name, profileImage } = userInfo
  const profileImageUrl = createDownloadUrl(profileImage?.downloadUrl)
  const totalAmount = cartItems?.reduce((total, item) => total + item.price, 0) || 0
  const cartItemCount = cartItems?.length || 0

  return (
    <Box>
      <IconButton
        size={iconButtonSize}
        aria-label="shopping cart"
        aria-controls="menu-shopping-cart"
        aria-haspopup="true"
        onClick={(e) => {
          setAnchorShoppingCartEl(e.currentTarget)
          fetchCartItems()
        }}
        color="inherit"
      >
        <Badge
          badgeContent={cartItemCount > 99 ? "99+" : cartItemCount}
          color="error"
          sx={{
            "& .MuiBadge-badge": {
              top: "10%",
              right: "10%",
            },
          }}
        >
          <Avatar sx={{ bgcolor: "transparent" }}>
            <ShoppingCartIcon color="secondary" />
          </Avatar>
        </Badge>
      </IconButton>

      <Menu
        id="menu-shopping-cart"
        anchorEl={anchorShoppingCartEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorShoppingCartEl)}
        onClose={() => setAnchorShoppingCartEl(null)}
      >
        <Box sx={{ width: "90vw", maxWidth: "300px" }}>
          {cartItems?.length > 0 ? (
            <>
              {cartItems.map((item) => (
                <MenuItem key={item._id} component={Link} to={`/musicDetail/${item._id}`}>
                  <ListItemAvatar>
                    <Box
                      component="img"
                      src={createDownloadUrl(item.project?.image?.downloadUrl) || WMC_LOGO}
                      alt={item.name}
                      sx={{ width: 50, height: 50, borderRadius: 1, objectFit: "cover" }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.name}
                    secondary={
                      <>
                        <Typography variant="body2" color="text.secondary">
                          {item.ownedUser}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          ${item.price}
                        </Typography>
                      </>
                    }
                  />
                  {removingItemId === item._id ? (
                    <CircularProgress size={20} />
                  ) : (
                    <IconButton
                      size="small"
                      edge="end"
                      aria-label="delete"
                      onClick={(e) => {
                        e.preventDefault()
                        removeFromCart(item._id)
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  )}
                </MenuItem>
              ))}

              {/* Total Amount */}
              <Box sx={{ p: 2, textAlign: "center" }}>
                <Typography variant="h6" color="primary">
                  <English>Total:</English>
                  <Chinese>總計：</Chinese>${totalAmount.toFixed(2)}
                </Typography>
              </Box>

              {/* Checkout Button */}
              <Box sx={{ p: 2, textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/shoppingCart" // Navigate to /shoppingCart
                >
                  <English>Checkout</English>
                  <Chinese>結帳</Chinese>
                </Button>
              </Box>
            </>
          ) : (
            <Typography variant="body2" color="text.secondary" align="center">
              <English>Your cart is empty</English>
              <Chinese>您的購物車是空的</Chinese>
            </Typography>
          )}
        </Box>
      </Menu>

      <IconButton
        size={iconButtonSize}
        aria-label="notifications"
        aria-controls="menu-notification"
        aria-haspopup="true"
        onClick={(e) => {
          if (userNotification?.length > 0) setAnchorNotificationEl(e.currentTarget)
        }}
        color="inherit"
      >
        <Avatar sx={{ bgcolor: "transparent" }}>
          <NotificationAddOutlinedIcon color="secondary" />
        </Avatar>
      </IconButton>
      <Menu
        id="menu-notification"
        anchorEl={anchorNotificationEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorNotificationEl)}
        onClose={() => setAnchorNotificationEl(null)}
      >
        <Box sx={{ width: "90vw", maxWidth: "500px" }}>
          <NotificationtList
            notification={userNotification}
            handleClickNotification={updateUserNotification}
          />
        </Box>
      </Menu>

      <IconButton
        size={iconButtonSize}
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        color="inherit"
      >
        <Avatar alt="User Pic" src={profileImageUrl} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Box sx={{ width: 200 }}>
          <MenuItem component={NavLink} to={`/user/self/portfolio`}>
            <ListItemAvatar>
              <Avatar alt="User Pic" src={profileImageUrl} />
            </ListItemAvatar>
            <ListItemText
              primary={name}
              secondary={
                <Typography variant="body2" color="primary">
                  <English>View Profile</English>
                  <Chinese>查看個人資料</Chinese>
                </Typography>
              }
            />
          </MenuItem>
          <Divider />
          <MenuItem component={Link} to={`/user/self/edit`}>
            <English>Edit Profile</English>
            <Chinese>編輯個人資料</Chinese>
          </MenuItem>

          <MenuItem component={Link} to={`/history/purchased`}>
            <English>Purchasing Record</English>
            <Chinese>購買記錄</Chinese>
          </MenuItem>
          <MenuItem component={Link} to={`/history/subscription`}>
            <English>Subscription Plan</English>
            <Chinese>訂閱計劃</Chinese>
          </MenuItem>
          <MenuItem component={Link} to={`/history/saved`}>
            <English>Saved Music</English>
            <Chinese>收藏音樂</Chinese>
          </MenuItem>
          <MenuItem component={Link} to={`/history/downloaded`}>
            <English>Download Record</English>
            <Chinese>下載記錄</Chinese>
          </MenuItem>

          <MenuItem onClick={handleClose}>
            <English>Terms of use</English>
            <Chinese>使用條款</Chinese>
          </MenuItem>

          <Divider />
          <Box sx={{ py: 1 }}>
            <LanguageToggle />
          </Box>

          <MenuItem onClick={handleLogOut}>
            <English>Sign Out</English>
            <Chinese>登出</Chinese>
          </MenuItem>
        </Box>
      </Menu>
    </Box>
  )
}
