import { Tempo } from "../types/tempo"
import { getRequest, postRequest } from "./request"

const Music: any = {}

Music.getMusic = async () => {
  return await getRequest({ url: `/music` })
}

Music.getMusicDetail = async (musicId: string) => {
  return getRequest({ url: `/music/musicDetail/${musicId}` })
}

Music.getMusicCount = async () => {
  return getRequest({ url: `/music/count` })
}

Music.getMusicByPage = async (pageIndex: number, pageSize: number) => {
  return getRequest({ url: `/music/page/${pageIndex}/${pageSize}` })
}

Music.retrieveRandomByStyles = async (styles: string[]) => {
  const data = { styles }
  return postRequest({
    url: `/music/retrieveRandomByStyles`,
    data,
    needToken: false,
  })
}

Music.retrieveRandomByStylesWithPurchased = async (styles: string[]) => {
  const data = { styles }
  return postRequest({
    url: `/music/retrieveRandomByStylesWithPurchased`,
    data,
  })
}

Music.retrieveRandomByUser = async (userId: string) => {
  const data = { userId }
  return postRequest({
    url: `/music/retrieveMusicsByUserProjects`,
    data,
    needToken: false,
  })
}

Music.retrieveRandomByUserWithPurchased = async (userId: string) => {
  const data = { userId }
  return postRequest({
    url: `/music/retrieveMusicsByUserProjectsWithPurchased`,
    data,
  })
}

Music.retrieveAllByUser = async (userId: string) => {
  const data = { userId }
  return postRequest({
    url: `/music/retrieveAllMusicsByUser`,
    data,
    needToken: false,
  })
}

Music.retrieveAllByUserWithPurchased = async (userId: string) => {
  const data = { userId }
  return postRequest({
    url: `/music/retrieveAllMusicsByUserWithPurchased`,
    data,
  })
}

Music.retrieve = async ({
  pageIndex,
  pageSize,
  styles,
  tempo,
  sortBy,
  sortOrder,
  musicType,
}: {
  pageIndex: number
  pageSize: number
  styles: string[]
  tempo: Tempo[]
  sortBy: string
  sortOrder: "ASC" | "DESC"
  musicType: "ALL" | "PREMIUM" | "STANDARD" | "SOUNDEFFECT" | "FREE"
}) => {
  const data = {
    pageIndex,
    pageSize,
    styles: styles.length > 0 ? styles : undefined,
    tempo: tempo.length > 0 ? tempo : undefined,
    sortBy,
    sortOrder,
    musicType,
  }
  return postRequest({
    url: `/music/retrieve`,
    data,
    needToken: false,
  })
}

Music.retrieveWithPurchased = async ({
  pageIndex,
  pageSize,
  styles,
  tempo,
  sortBy,
  sortOrder,
}: {
  pageIndex: number
  pageSize: number
  styles: string[]
  tempo: Tempo[]
  sortBy: string
  sortOrder: "asc" | "desc"
}) => {
  const data = {
    pageIndex,
    pageSize,
    styles: styles.length > 0 ? styles : undefined,
    tempo: tempo.length > 0 ? tempo : undefined,
    sortBy,
    sortOrder,
  }
  return postRequest({
    url: `/music/retrieveWithPurchased`,
    data,
  })
}

Music.getMusicDetailWithPurchased = async (musicId: string) => {
  return getRequest({ url: `/music/musicDetailWithPurchased/${musicId}` })
}

Music.getDownloadedMusic = async () => {
  return getRequest({ url: `/music/downloaded` })
}

export default Music
