import React, { useState } from "react"
import { Box, Button, Divider, Grid } from "@mui/material"
import { MarketPlaceTable } from "../../../component"
import { MusicAPI } from "../../../api"
import { FilterState } from "../../../types/marketPlace"
import useMUIMediaQuery from "../../../hooks/useMUIMedia"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import SortIcon from "@mui/icons-material/Sort"
import FilterByMusicStyleMenu from "../../../component/MarketPlace/FilterMenu/MusicStyle/FilterByMusicStyleMenu"
import FilterByTempoMenu from "../../../component/MarketPlace/FilterMenu/Tempo/FilterByTempoMenu"
import MobileFilterByMusicStyleMenu from "../../../component/MarketPlace/FilterMenu/MusicStyle/MobileFilterByMusicStyleMenu"
import MobileMarketPlaceSortMenu from "../../../component/MarketPlace/SortMenu/MobileMarketPlaceSortMenu"
import MarketPlaceSortMenu from "../../../component/MarketPlace/SortMenu/MarketPlaceSortMenu"

/**
 * Premium tab in market place page
 */
export default function Premium() {
  const [marketPlaceData, setMarketPlaceData] = React.useState([])
  const [, setError] = React.useState("")
  const { isMobile } = useMUIMediaQuery()

  // Floating UI control
  const [openFilterMenu, setOpenFilterMenu] = useState(false)
  const [openSortMenu, setOpenSortMenu] = useState(false)

  // Filtering and sorting state for getMarketPlaceData
  const [filter, setFilter] = React.useState<FilterState>({
    style: [],
    tempo: [],
    sortBy: "uploadTimestamp",
    sortOrder: "DESC",
  })

  React.useEffect(() => {
    const getMarketPlaceData = async () => {
      const response = await MusicAPI.retrieve({
        pageIndex: 0,
        pageSize: 0,
        styles: filter?.style,
        tempo: filter?.tempo,
        sortBy: filter?.sortBy,
        sortOrder: filter?.sortOrder,
        musicType: "PREMIUM",
      })
      if (response?.err) {
        setError(response?.err)
        return
      }
      setMarketPlaceData(response?.res)
    }
    getMarketPlaceData()
  }, [filter])

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container columnSpacing={2}>
          {!isMobile && (
            <Grid item xs={2}>
              <FilterByMusicStyleMenu filter={filter} setFilter={setFilter} />
              <Divider sx={{ my: 2 }} />
              <FilterByTempoMenu filter={filter} setFilter={setFilter} />
            </Grid>
          )}

          <Grid item xs={isMobile ? 12 : 10}>
            {isMobile ? (
              <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
                <Button
                  variant="outlined"
                  startIcon={<FilterAltIcon />}
                  onClick={() => setOpenFilterMenu(true)}
                  sx={{ textTransform: "none", mr: 1 }}
                >
                  Filter
                </Button>

                <Button
                  variant="outlined"
                  startIcon={<SortIcon />}
                  onClick={() => setOpenSortMenu(true)}
                  sx={{ textTransform: "none" }}
                >
                  Sort
                </Button>
              </Box>
            ) : (
              <MarketPlaceSortMenu filter={filter} setFilter={setFilter} />
            )}

            <MarketPlaceTable data={marketPlaceData} />
          </Grid>
        </Grid>
      </Box>

      {/* Floating UI */}
      {isMobile && (
        <MobileFilterByMusicStyleMenu
          filter={filter}
          setFilter={setFilter}
          open={openFilterMenu}
          onClose={() => setOpenFilterMenu(false)}
        />
      )}

      {isMobile && (
        <MobileMarketPlaceSortMenu
          filter={filter}
          setFilter={setFilter}
          open={openSortMenu}
          onClose={() => setOpenSortMenu(false)}
        />
      )}
    </>
  )
}
