import { Button, SxProps, Theme, CircularProgress } from "@mui/material"

import DownloadIcon from "@mui/icons-material/Download"
import Chinese from "../../theme/Chinese"
import English from "../../theme/English"

const BUTTON_WIDTH = 150
const BUTTON_BORDER_RADIUS = 16

type DownloadButtonProps = {
  sx?: SxProps<Theme>
  onClick?: () => void
  isLoading?: boolean
}
/**
 * Market Place Download Button
 */
export default function DownloadButton(props: DownloadButtonProps) {
  const { sx, onClick, isLoading = false } = props

  return (
    <Button
      sx={{
        ...sx,
        width: BUTTON_WIDTH,
        display: "inline-flex",
        alignItems: "center",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: BUTTON_BORDER_RADIUS,
        overflow: "hidden",
        textTransform: "none",
      }}
      startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <DownloadIcon />}
      onClick={onClick}
      disabled={isLoading}
    >
      <English>Download</English>
      <Chinese>下載</Chinese>
    </Button>
  )
}
