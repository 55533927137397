import React, { useEffect, useState } from "react"
import { Box, Grid } from "@mui/material"
import { MusicAPI } from "../../api"
import DownloadedMusicTable from "./DownloadedMusicTable"
import { DownloadedMusicData } from "./historyTypes"
import { createDownloadUrl } from "../../util"

/**
 * Download Record Tab
 */
export default function DownloadRecord() {
  const [downloads, setDownloads] = React.useState<DownloadedMusicData[]>([])
  const [, setError] = useState("")

  useEffect(() => {
    const getDownloadRecords = async () => {
      const response = await MusicAPI.getDownloadedMusic()
      if (response?.err) {
        setError(response?.err)
        return
      }
      setDownloads(response?.res || [])
    }
    getDownloadRecords()
  }, [])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container columnSpacing={2}>
        <Grid item xs={10}>
          <DownloadedMusicTable
            data={downloads
              .map((download) => ({
                ...download,
                image: download.project?.image,
                musicUrl: createDownloadUrl(download.downloadUrl),
              }))
              .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
