import React from "react"
import {
  Grid,
  TextField,
  Button,
  Typography,
  MenuItem,
  Box,
  Paper,
  Snackbar,
  Alert,
  CircularProgress,
  Divider,
} from "@mui/material"
import { postRequest } from "../../api/request"
import { Page, PageContainer, PageTitle } from "../../component"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import EmailIcon from "@mui/icons-material/Email"
import English from "../../theme/English"
import Chinese from "../../theme/Chinese"

// Contact categories
const CONTACT_CATEGORIES = [
  { value: "general", label: "General Inquiry" },
  { value: "support", label: "Technical Support" },
  { value: "billing", label: "Billing Question" },
  { value: "feedback", label: "Feedback" },
  { value: "other", label: "Other" },
]

/**
 * Contact Us Form Component
 */
function ContactUs() {
  // Form states
  const [formState, setFormState] = React.useState({
    name: "",
    email: "",
    category: "general",
    message: "",
  })

  // Form validation errors
  const [errors, setErrors] = React.useState({
    name: "",
    email: "",
    message: "",
  })

  // UI states
  const [loading, setLoading] = React.useState(false)
  const [notification, setNotification] = React.useState({
    open: false,
    type: "success",
    message: "",
  })

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setFormState((prev) => ({ ...prev, [name]: value }))

    // Clear error when typing
    if (errors[name as keyof typeof errors]) {
      setErrors((prev) => ({ ...prev, [name]: "" }))
    }
  }

  const validateForm = () => {
    const newErrors = {
      name: formState.name ? "" : "Name is required",
      email: !formState.email
        ? "Email is required"
        : !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formState.email)
        ? "Invalid email address"
        : "",
      message: formState.message ? "" : "Message is required",
    }

    setErrors(newErrors)
    return !Object.values(newErrors).some((error) => error)
  }

  const handleFormSubmit = async () => {
    if (!validateForm()) return

    setLoading(true)
    try {
      // Format all form data into a structured message
      const formattedMessage = `
Name: ${formState.name}
Email: ${formState.email}
Category: ${
        CONTACT_CATEGORIES.find((c) => c.value === formState.category)?.label || formState.category
      }

Message:
${formState.message}
      `.trim()

      await postRequest({
        url: "/admin/contactus",
        data: {
          message: formattedMessage, // Send all form info in the message field
        },
      })

      // Reset form
      setFormState({
        name: "",
        email: "",
        category: "general",
        message: "",
      })

      setNotification({
        open: true,
        type: "success",
        message: "Thank you for your message. We'll get back to you as soon as possible.",
      })
    } catch (err) {
      console.error("Error submitting form:", err)
      setNotification({
        open: true,
        type: "error",
        message: "Something went wrong. Please try again later.",
      })
    } finally {
      setLoading(false)
    }
  }

  const handleCloseNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }))
  }

  return (
    <Page pageId={ContactUs} noPadding>
      <PageContainer>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Paper elevation={2} sx={{ p: 4, borderRadius: 2 }}>
              <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                  <EmailIcon sx={{ mr: 1, verticalAlign: "middle", fontSize: "1.2em" }} />
                  <English>Send us a message</English> <Chinese>發送訊息給我們</Chinese>
                </Typography>
                <Divider />
              </Box>

              <Grid container spacing={3}>
                {/* Name field */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="name"
                    label={
                      <>
                        <English>Your Name</English> <Chinese>您的姓名</Chinese>
                      </>
                    }
                    fullWidth
                    value={formState.name}
                    onChange={handleInputChange}
                    variant="outlined"
                    error={!!errors.name}
                    helperText={errors.name}
                    required
                  />
                </Grid>

                {/* Email field */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="email"
                    label={
                      <>
                        <English>Email Address</English> <Chinese>電子郵件地址</Chinese>
                      </>
                    }
                    fullWidth
                    value={formState.email}
                    onChange={handleInputChange}
                    variant="outlined"
                    error={!!errors.email}
                    helperText={errors.email}
                    required
                  />
                </Grid>

                {/* Category dropdown */}
                <Grid item xs={12}>
                  <TextField
                    select
                    name="category"
                    label={
                      <>
                        <English>What can we help you with?</English>{" "}
                        <Chinese>我們如何幫助您？</Chinese>
                      </>
                    }
                    fullWidth
                    value={formState.category}
                    onChange={handleInputChange}
                    variant="outlined"
                  >
                    {CONTACT_CATEGORIES.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <English>{option.label}</English>{" "}
                        <Chinese>{getCategoryTranslation(option.value)}</Chinese>
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* Message field */}
                <Grid item xs={12}>
                  <TextField
                    name="message"
                    label={
                      <>
                        <English>Your Message</English> <Chinese>您的訊息</Chinese>
                      </>
                    }
                    multiline
                    fullWidth
                    rows={6}
                    value={formState.message}
                    onChange={handleInputChange}
                    variant="outlined"
                    error={!!errors.message}
                    helperText={errors.message}
                    required
                  />
                </Grid>

                {/* Form controls */}
                <Grid item xs={12} sx={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleFormSubmit}
                    disabled={loading || !formState.name || !formState.email || !formState.message}
                    endIcon={
                      loading ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        <ArrowForwardIcon />
                      )
                    }
                    size="large"
                    sx={{ minWidth: 150 }}
                  >
                    {loading ? (
                      <>
                        <English>Sending...</English> <Chinese>傳送中...</Chinese>
                      </>
                    ) : (
                      <>
                        <English>Submit</English> <Chinese>提交</Chinese>
                      </>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Contact information sidebar */}
          <Grid item xs={12} md={4}>
            <Paper
              elevation={2}
              sx={{
                p: 4,
                borderRadius: 2,
                height: "100%",
                bgcolor: "primary.light",
                color: "primary.contrastText",
              }}
            >
              <Typography variant="h6" gutterBottom>
                <English>Contact Information</English> <Chinese>聯絡資訊</Chinese>
              </Typography>
              <Divider sx={{ borderColor: "rgba(255,255,255,0.3)", mb: 3 }} />

              <Box mb={4}>
                <Typography variant="subtitle1" fontWeight="bold">
                  <English>Email</English> <Chinese>電子郵件</Chinese>
                </Typography>
                <Typography variant="body2">admin@womucon.comm</Typography>
              </Box>

              <Box>
                <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                  <English>
                    {`We're here to help! Fill out the form and we'll get back to you as soon as possible`}
                    .
                  </English>
                  <Chinese>我們隨時為您提供協助！填寫表格後，我們會盡快回覆您。</Chinese>
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        {/* Notification */}
        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={handleCloseNotification}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseNotification}
            severity={notification.type as "success" | "error"}
            sx={{ width: "100%" }}
            elevation={6}
            variant="filled"
          >
            {notification.type === "success" ? (
              <>
                <English>{notification.message}</English>{" "}
                <Chinese>感謝您的訊息。我們會盡快回覆您。</Chinese>
              </>
            ) : (
              <>
                <English>{notification.message}</English> <Chinese>出現錯誤。請稍後再試。</Chinese>
              </>
            )}
          </Alert>
        </Snackbar>
      </PageContainer>
    </Page>
  )
}

// Helper function to get Traditional Chinese translations for categories
const getCategoryTranslation = (value: string) => {
  switch (value) {
    case "general":
      return "一般查詢"
    case "support":
      return "技術支援"
    case "billing":
      return "帳單問題"
    case "feedback":
      return "意見反饋"
    case "other":
      return "其他"
    default:
      return "一般查詢"
  }
}

export default ContactUs
