import { Button, Grid } from "@mui/material"
import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { MusicAPI, SavedMusicAPI } from "../../api"
import MusicDetailMusicCard from "../../component/MusicDetailMusicCard/MusicDetailMusicCard"
import ProfileHeader from "../../component/ProfileHeader/ProfileHeader"
import { useUserInfo } from "../../context/UserInfoProvider"
import { createDownloadUrl } from "../../util"
import English from "../../theme/English"
import Chinese from "../../theme/Chinese"
/**
 * This is the user portfolio Page, this page should be viewable to anyone
 * The editing of portfolio page should be restricted to the user itself and possibly the admin
 */
export default function SelfPortfolioPage2() {
  const { userInfo, updateUserInfo, userToken } = useUserInfo()
  const [musics, setMusics] = React.useState<any[]>([])

  const [savedMusics, setSavedMusics] = React.useState<string[]>([])

  useEffect(() => {
    updateUserInfo()
  }, [updateUserInfo])

  useEffect(() => {
    const trigger = async () => {
      const { res, error } = userToken
        ? await MusicAPI.retrieveAllByUserWithPurchased(userInfo._id)
        : await MusicAPI.retrieveAllByUser(userInfo._id)

      if (error) {
        setMusics([])
      } else {
        setMusics(res)
      }
    }

    trigger()
  }, [userInfo, userToken])

  useEffect(() => {
    const fetchSavedMusics = async () => {
      try {
        const response = await SavedMusicAPI.getSavedMusic()
        if (response?.err) {
          console.error("Failed to get saved musics")
          return
        }
        console.log("Saved musics:", response)
        setSavedMusics(response?.res?.map((t: { musicId: { _id: string } }) => t.musicId._id))
      } catch (err) {
        console.error("Failed to get saved musics:", err)
      }
    }
    if (userToken) fetchSavedMusics()
  }, [userToken])

  if (!userInfo) return <></>

  const coverImageUrl = createDownloadUrl(userInfo.coverImage?.downloadUrl)

  const editBtn = (
    <Button to={`/user/self/edit`} component={Link} variant="text" color="primary">
      <English>Edit</English>
      <Chinese>編輯</Chinese>
    </Button>
  )

  return (
    <>
      <ProfileHeader isSelf={true} {...userInfo} editBtn={editBtn} coverImageUrl={coverImageUrl} />
      <Grid container spacing={3}>
        {musics?.map((t: any, i) => {
          return (
            <Grid item key={i} xs={12} md={3}>
              <MusicDetailMusicCard
                _id={t._id}
                name={t.projectName}
                description={t.description}
                imageUrl={t.imageUrl}
                image={t.image}
                saved={t.saved}
                purchased={t.purchased}
                price={t.price}
                project={t.project}
                savedMusics={savedMusics}
                setSavedMusics={setSavedMusics}
                downloadUrl={t.downloadUrl}
                watermarkUrl={t.watermarkUrl}
                musicType={t.musicType}
              />
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}
