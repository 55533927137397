import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import { Box, Button } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import { useLanguage } from "../../../context/LanguageProvider"
import { FilterState } from "../../../types/marketPlace"
import { getSortButtonsConfig } from "./config"

/**
 * Sort menu in market place
 */
export default function MarketPlaceSortMenu({
  filter,
  setFilter,
}: {
  filter: FilterState
  setFilter: Dispatch<SetStateAction<FilterState>>
}) {
  const { language } = useLanguage()
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1, mb: 2 }}>
      {getSortButtonsConfig(language)?.map(({ type, label, key }) => {
        const isCurrentSortKey = filter?.sortBy === key
        return (
          <Button
            size="medium"
            key={`sort_${type}`}
            variant="outlined"
            sx={{ textTransform: "none" }}
            onClick={() => {
              const newSortOrder = filter?.sortOrder === "DESC" ? "ASC" : "DESC"
              setFilter((prevFilter) => {
                return { ...prevFilter, sortBy: key, sortOrder: newSortOrder }
              })
            }}
          >
            {label}
            {!isCurrentSortKey ? (
              <></>
            ) : filter?.sortOrder === "DESC" ? (
              <ArrowDropDownIcon />
            ) : (
              <ArrowDropUpIcon />
            )}
          </Button>
        )
      })}
    </Box>
  )
}
