import React from "react"
import { Page, PageContainer, ProjectBasicInfo, ProjectInstruments } from "../../component"
import { Box, Button, Grid, SelectChangeEvent } from "@mui/material"
import { useParams, useNavigate } from "react-router-dom"
import { ProjectAPI } from "../../api"
import { pull, clone } from "lodash"
import { createDownloadUrl } from "../../util"

/**
 * Edit Project Detail Page
 */
export default function EditProjectDetailPage() {
  const navigate = useNavigate()
  const { projectId } = useParams()
  const [pageState, setPageState] = React.useState<undefined | any>()
  const [instrumentsState, setInstrumentsState] = React.useState<string[]>([])
  const [stylesState, setStylesState] = React.useState<string[]>([])
  const [uploadImage, setUploadImage] = React.useState<File | null>(null)

  const handleUploadImage = (file: File | null) => {
    setUploadImage(file)
  }
  const setState = (name: string, value: number | string | null) => {
    setPageState((prev: any) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target
    setState(name, value)
  }

  const handleMetreChange = (e: SelectChangeEvent<string>) => {
    const [metreUpper, metreLower] = e.target.value.split("/")

    setState("metreUpper", metreUpper)
    setState("metreLower", metreLower)
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setState(name, value)
  }

  const handleDateChange = (val: string | null) => {
    setState("deadline", val)
  }

  const handleTempoChange = (val: number) => {
    setState("tempo", val)
  }

  const handleCheckBoxToggleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      const styles = clone(stylesState)
      styles.includes(value) ? pull(styles, value) : styles.push(value)
      setStylesState(styles)
    },
    [stylesState]
  )

  const handleAddInstrument = React.useCallback(
    (value: string) => {
      const instruments = clone(instrumentsState)
      const instrument = value.toLowerCase()
      instruments.push(instrument)
      setInstrumentsState(instruments)
    },
    [instrumentsState]
  )

  const handleRemoveInstrument = React.useCallback(
    (value: string) => {
      const instruments = clone(instrumentsState)
      const instrument = value.toLowerCase()
      pull(instruments, instrument)
      setInstrumentsState(instruments)
    },
    [instrumentsState]
  )

  const handleFormSubmit = async () => {
    const data = {
      ...pageState,
      instruments: instrumentsState,
      styles: stylesState,
    }
    ProjectAPI.updateInfo(projectId, data)

    await ProjectAPI.updateInfo(projectId, data)
    if (uploadImage) {
      await ProjectAPI.uploadProjectImage(projectId, uploadImage)
    }
    navigate(`/projects/${projectId}`)
  }

  const handleCancel = () => {
    location.assign(`/projects/${projectId}`)
  }

  React.useEffect(() => {
    /**
     * Trigger API Call
     */
    async function trigger() {
      const getProjectRes = await ProjectAPI.getProject(projectId)
      const {
        name,
        description,
        deadline,
        tempo,
        metreUpper,
        metreLower,
        styles,
        instruments,
        image,
        musicKey,
      } = getProjectRes.res
      setPageState({
        name,
        description,
        deadline,
        tempo,
        metreUpper,
        metreLower,
        image,
        musicKey,
      })
      setInstrumentsState(instruments)
      setStylesState(styles)
    }

    trigger()
  }, [projectId])

  if (!pageState) return <></>
  const profileImageUrl = createDownloadUrl(pageState?.image?.downloadUrl)

  return (
    <Page pageId={EditProjectDetailPage} noPadding>
      <PageContainer>
        <Grid container>
          <Grid item xs={12}>
            <ProjectBasicInfo
              handlePreviewImage={() => {}}
              handleUploadImage={handleUploadImage}
              handleSelectChange={handleSelectChange}
              handleTextChange={handleTextChange}
              handleDateChange={handleDateChange}
              handleTempoChange={handleTempoChange}
              handleMetreChange={handleMetreChange}
              name={pageState.name}
              description={pageState.description}
              deadline={pageState.deadline}
              tempo={pageState.tempo}
              metreUpper={pageState.metreUpper}
              metreLower={pageState.metreLower}
              musicKey={pageState.musicKey}
              imageUrl={profileImageUrl}
              // TODO
              handleMusicTypeChange={() => console.log("Change")}
              musicType={""}
              currency={""}
              price={""}
            />
            <ProjectInstruments
              selectedInstruments={instrumentsState}
              selectedStyles={stylesState}
              selectedMoods={[]}
              selectedThemes={[]}
              handleCheckBoxToggleChange={handleCheckBoxToggleChange}
              handleAddInstrument={handleAddInstrument}
              handleRemoveInstrument={handleRemoveInstrument}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "right" }}>
            <Box py={2}>
              <Button variant="outlined" onClick={handleCancel} sx={{ mr: 2 }}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleFormSubmit}>
                Update
              </Button>
            </Box>
          </Grid>
        </Grid>
      </PageContainer>
    </Page>
  )
}
