import { Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material"
import Chinese from "../../../../theme/Chinese"
import English from "../../../../theme/English"
import { FilterState } from "../../../../types/marketPlace"
import { Dispatch, SetStateAction } from "react"
import { Tempo } from "../../../../types/tempo"

const TEMPO_FILTER_CONFIG: { type: Tempo; label: string }[] = [
  { type: "verySlow", label: "Below 60 BPM" },
  { type: "slow", label: "Slow 60-89 BPM" },
  { type: "medium", label: "Medium 90-109 BPM" },
  { type: "upbeat", label: "Upbeat (110-139 BPM)" },
  { type: "fast", label: "Fast (140-159 BPM)" },
  { type: "veryFast", label: "Very fast (160+ BPM)" },
]

/**
 * Filter by tempo in market place
 */
export default function FilterByTempoMenu({
  filter,
  setFilter,
}: {
  filter: FilterState
  setFilter: Dispatch<SetStateAction<FilterState>>
}) {
  const updateFilter = (tempoType: string) => {
    if (!tempoType) return
    setFilter((prevFilter) => {
      const updatedTempo = prevFilter?.tempo?.includes(tempoType)
        ? prevFilter?.tempo?.filter((tempo) => tempo !== tempoType)
        : [...prevFilter.tempo, tempoType]
      return { ...prevFilter, tempo: updatedTempo }
    })
  }

  return (
    <>
      <Typography variant="body1" sx={{ mb: 2 }}>
        <English>Tempo</English>
        <Chinese>速度</Chinese>
      </Typography>

      <FormGroup>
        {TEMPO_FILTER_CONFIG?.map(({ type, label }) => (
          <FormControlLabel
            key={type}
            control={<Checkbox />}
            label={label}
            onChange={() => updateFilter(type)}
          />
        ))}
      </FormGroup>
    </>
  )
}
