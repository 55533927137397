import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DrawerAppBar } from "./component"
import FooterMusicPlayer from "./component/FooterMusicPlayer.tsx/FooterMusicPlayer"
import { CartProvider, MusicProvider, NotificationProvider, UserInfoProvider } from "./context"
import { LanguageProvider } from "./context/LanguageProvider" // new import
import AppRouter from "./router/AppRouter"
import { theme } from "./theme/theme"
import { musicStyles } from "./component/UserForm/user"
import { renderIcon as renderMusicIcon } from "./component/MusicStyleList/musicStyleData"

/**
 * Main application component.
 *
 * @returns {JSX.Element} The rendered application.
 */
export default function App() {
  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret: "test",
  // }

  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LanguageProvider>
            <MusicProvider>
              <NotificationProvider>
                <UserInfoProvider>
                  <CartProvider>
                    <DrawerAppBar>
                      <AppRouter />
                    </DrawerAppBar>
                    <FooterMusicPlayer />
                  </CartProvider>
                </UserInfoProvider>
              </NotificationProvider>
            </MusicProvider>
          </LanguageProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  )
}
