import { capitalize } from "lodash"
import { config } from "../config/client-config"
import { intervalToDuration } from "date-fns"

const fileServer = config.fileServerUrl

export const getCookieValue = (name: string) =>
  document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || ""

export const createDownloadUrl = (downloadUrl?: string, token?: string) => {
  let url = downloadUrl ? `${fileServer}/${downloadUrl}` : ""

  if (token && url) {
    url += `?token=${token}`
  }

  return url
}

export const formatPublishDateTime = (
  publishDateTime: Date | undefined,
  language: string = "en"
): string => {
  if (!publishDateTime) return ""
  const today = new Date()
  const diffTime = Math.abs(today.getTime() - publishDateTime.getTime())
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
  if (diffDays === 0) {
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60))
    if (diffHours === 0) {
      const diffMinutes = Math.floor(diffTime / (1000 * 60))
      if (diffMinutes === 0) {
        return language === "zh" ? "剛剛" : "Just now"
      }
      return language === "zh" ? `${diffMinutes} 分鐘前` : `${diffMinutes} min ago`
    }
    return language === "zh" ? `${diffHours} 小時前` : `${diffHours} h ago`
  }
  if (diffDays < 11) {
    return language === "zh" ? `${diffDays} 天前` : `${diffDays} d ago`
  }
  if (language === "zh") {
    // Chinese format: Month and day with Chinese characters
    return `${publishDateTime.getMonth() + 1}月${publishDateTime.getDate()}日`
  }
  return (
    publishDateTime.getDate() + " " + publishDateTime.toLocaleString("en-us", { month: "short" })
  )
}

export const loadScript = (src: string) => {
  // creates a <script> tag and append it to the page
  // this causes the script with given src to start loading and run when complete
  let script = document.createElement("script")
  script.src = src
  document.head.append(script)
}

/**
 * Creates a streaming URL for a music track
 * @param musicId The ID of the music to stream
 * @returns The streaming URL
 */
export const createStreamingUrl = (musicId: string): string => {
  // Return the API endpoint path, not the full URL
  // The actual URL will be handled by the streamRequest function
  return `/music/stream/${musicId}`
}

export { logOut } from "./auth-utils"
export { default as AudioRecorder } from "./recorder"
export { default as Metronome } from "./metronome"

export const rbStringHandling = (str: string) => {
  // Special FE handling for R&B string display
  if (!str) return "--"
  return str?.replace(/\w+/g, capitalize)
}

export const formatSecondsToMMSS = (seconds: number): string => {
  if (!seconds) return "--"

  // Convert seconds to a duration object
  const duration = intervalToDuration({
    start: 0,
    end: seconds * 1000, // Convert seconds to milliseconds
  })

  // Extract minutes and seconds (default to 0 if undefined)
  const minutes = duration.minutes || 0
  const secs = duration.seconds || 0

  // Format as mm:ss with leading zeros
  return `${minutes}:${secs.toString().padStart(2, "0")}`
}
