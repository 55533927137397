import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { translateInstrumentText } from "../../component/InstrumentList/instrumentData"
import { translateMusicStyle } from "../../component/MusicStyleList/musicStyleData"
import { useLanguage } from "../../context/LanguageProvider"
import Chinese from "../../theme/Chinese"
import English from "../../theme/English"
import { colors } from "../../theme/colors"
import { translateMood } from "../../component/MoodList/moodData"
import { translateTheme } from "../../component/ThemeList/themeData"

type MusicDetailInformationProps = {
  instruments: string[]
  image?: {
    downloadUrl: string
  }
  metreUpper: number
  metreLower: number
  tempo: string
  styles: string[]
  musicKey: string
  moods?: string[]
  themes?: string[]
}
/**
 * Music Detail Information Section
 */
export default function MusicDetailInformation(props: MusicDetailInformationProps) {
  const { instruments, metreUpper, metreLower, tempo, styles, musicKey, moods, themes } = props

  const { language } = useLanguage() // retrieve language state

  return (
    <Grid item container padding={2} xs={12} marginTop={2} bgcolor={colors.grey3}>
      <Grid item xs={12}>
        <Typography variant="h5" color={colors.teal}>
          <English>Information</English>
          <Chinese>資訊</Chinese>
        </Typography>
      </Grid>
      <Grid item md={4}>
        <Typography variant="subtitle2" color={colors.grey2}>
          <English>Style</English>
          <Chinese>風格</Chinese>
        </Typography>
        <Typography variant="body1">
          {styles?.map((style: string) => translateMusicStyle(style, language)).join(", ")}
        </Typography>
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2" color={colors.grey2}>
          <English>Instruments</English>
          <Chinese>樂器</Chinese>
        </Typography>
        <Typography variant="body1">
          {instruments
            ?.map((instrument: string) =>
              translateInstrumentText(instrument?.capitalize(), language)
            )
            .join(", ")}
        </Typography>
      </Grid>

      {moods && moods.length > 0 && (
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" color={colors.grey2}>
            <English>Moods</English>
            <Chinese>情緒</Chinese>
          </Typography>
          <Typography variant="body1">
            {moods.map((mood) => translateMood(mood.capitalize(), language)).join(", ")}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2" color={colors.grey2}>
          <English>Tempo</English>
          <Chinese>節奏</Chinese>
        </Typography>
        <Typography variant="body1">{tempo}</Typography>
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2" color={colors.grey2}>
          <English>Music Key</English>
          <Chinese>調</Chinese>
        </Typography>
        <Typography variant="body1">{musicKey}</Typography>
      </Grid>

      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2" color={colors.grey2}>
          <English>Metre</English>
          <Chinese>拍子</Chinese>
        </Typography>
        <Typography variant="body1">{`${metreUpper}/${metreLower}`}</Typography>
      </Grid>

      {themes && themes.length > 0 && (
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" color={colors.grey2}>
            <English>Themes</English>
            <Chinese>主題</Chinese>
          </Typography>
          <Typography variant="body1">
            {themes.map((theme) => translateTheme(theme.capitalize(), language)).join(", ")}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}
