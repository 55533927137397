import React, { createContext, useContext, useState, ReactNode, useEffect } from "react"

type Language = "en" | "zh"

interface LanguageContextProps {
  language: Language
  setLanguage: React.Dispatch<React.SetStateAction<Language>>
}

const LanguageContext = createContext<LanguageContextProps | undefined>(undefined)

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const [language, setLanguage] = useState<Language>(() => {
    const saved = localStorage.getItem("womucon-apps-language")
    return saved === "zh" ? "zh" : "en"
  })

  useEffect(() => {
    localStorage.setItem("womucon-apps-language", language)
  }, [language])

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguage = () => {
  const context = useContext(LanguageContext)
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider")
  }
  return context
}
