import { Box, Button, Stack, Typography } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { SubscriptionAPI, SubscriptionPlanAPI } from "../../api"
import SubscribeSection from "../../component/SubscriptionSection/SubscriptionSection"
import { useLanguage } from "../../context/LanguageProvider"
import Chinese from "../../theme/Chinese"
import { colors } from "../../theme/colors"
import English from "../../theme/English"

interface SubscriptionPlan {
  planType: string
  title: string
  title_tc: string
  price: number
  billingCycle: string
  features: string[]
  features_tc: string[]
  stripeId?: string
  stripeId_tc?: string
  isContactUs?: boolean
}
/**
 * Table for order music
 */
export default function SubscriptionTab() {
  const [subscribed, setSubscribed] = useState(false)
  const [planType, setPlanType] = useState("")
  const [plans, setPlans] = useState<SubscriptionPlan[]>([])
  const [, setError] = useState("")
  const [cancelled, setCancelled] = useState(false)
  const [cancelledAt, setCancelledAt] = useState("")
  const [subscription, setSubscription] = useState<any>()
  const { language } = useLanguage()

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const response = await SubscriptionAPI.checkSubscription()
        if (response?.err) {
          setError(response?.err)
          return
        }
        setSubscribed(response?.res.success)
        setPlanType(response?.res.subscription?.planType)
        setCancelled(response?.res.subscription?.cancelled)
        setCancelledAt(response?.res.subscription?.cancelledAt)
        setSubscription(response?.res.subscription)
      } catch (error) {
        console.error("Error checking subscription:", error)
      }
    }

    const fetchPlans = async () => {
      try {
        const response = await SubscriptionPlanAPI.getAll()
        if (response?.err) {
          setError(response?.err)
          return
        }
        setPlans(response?.res?.plans || [])
      } catch (error) {
        console.error("Error fetching plans:", error)
      }
    }

    checkSubscription()
    fetchPlans()
  }, [])

  const planDetail = useMemo(() => {
    return plans.find((plan) => plan.planType === planType)
  }, [plans, planType])

  const handleCancelSubscription = async () => {
    const res = await SubscriptionAPI.cancel()
    if (!res?.err) {
      setCancelled(true)
      console.log(res)
      console.log(res?.res?.cancelledAt)
      setCancelledAt(res?.res?.cancelledAt)
    }
  }
  console.log(cancelledAt)

  const feat = useMemo(() => {
    return language === "zh" ? planDetail?.features_tc : planDetail?.features
  }, [language, planDetail?.features, planDetail?.features_tc])
  return (
    <Stack
      sx={{
        padding: 2,
        marginTop: 4,
        width: "100%",
      }}
    >
      {subscribed ? (
        <Box sx={{ padding: 2, border: `1px solid ${colors.darkGrey2}` }}>
          <Box sx={{ padding: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              <>
                <English>{planDetail?.title} plan</English>
                <Chinese>{planDetail?.title_tc} </Chinese>
              </>
            </Typography>
            <Box sx={{ display: "flex", alignItems: "baseline" }}>
              <Typography variant="h4" sx={{ color: "#fff", marginRight: 1 }}>
                {planDetail?.price ? `$${planDetail.price}` : ""}
              </Typography>
              <Typography variant="body2" sx={{ color: "#aaa" }}>
                {language === "zh"
                  ? `${planDetail?.billingCycle === "monthly" ? "每月" : "每年"} 付款`
                  : `Billed ${planDetail?.billingCycle}`}
              </Typography>
            </Box>
            {subscription?.endDate && (
              <Typography variant="body2" sx={{ color: "#aaa", marginTop: 1 }}>
                <English>Current period ends on:</English>{" "}
                {new Date(subscription.endDate).toLocaleDateString()}
              </Typography>
            )}
            {cancelled && (
              <Typography variant="body2" sx={{ color: "red", marginTop: 1 }}>
                <>
                  <English>This subscription has been cancelled on</English>{" "}
                  <Chinese>此訂閱已於</Chinese>
                </>
                {new Date(cancelledAt).toLocaleDateString()}
                <Chinese>取消</Chinese>.
              </Typography>
            )}
            <Box
              component="ul"
              sx={{
                paddingY: 2,
                paddingX: 4,
                marginTop: 2,
                backgroundColor: colors.darkGrey2,
              }}
            >
              {feat?.map((feature, index) => (
                <Typography
                  component="li"
                  variant="body2"
                  key={index}
                  sx={{ color: "#fff", marginBottom: 1 }}
                >
                  {feature}
                </Typography>
              ))}
            </Box>

            {!cancelled && (
              <Button variant="contained" onClick={handleCancelSubscription}>
                <>
                  <English>Cancel Subscription</English>
                  <Chinese>取消訂閱</Chinese>
                </>
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <SubscribeSection />
      )}
    </Stack>
  )
}
