import { Box, Typography, styled } from "@mui/material"
import { useLanguage } from "../../context/LanguageProvider"
import React, { useMemo } from "react"
import Chinese from "../../theme/Chinese"
import English from "../../theme/English"
import { ReactComponent as LogoSVG } from "../../assets/brands/logo.svg"

const ToggleContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
  width: "80px",
  height: "34px",
  padding: "3px",
  borderRadius: "30px",
  backgroundColor: theme.palette.divider,
  cursor: "pointer",
  transition: "all 0.3s ease",
  "& .active-icon": {
    opacity: 0,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    "& .inactive-icon": {
      transform: "scale(1.2)",
      opacity: 0.8,
    },
  },
}))

const ToggleButton = styled(Box)<{ isactive: string }>(({ theme, isactive }) => ({
  position: "absolute",
  left: isactive === "true" ? "calc(100% - 34px)" : "3px",
  width: "28px",
  height: "28px",
  borderRadius: "50%",
  backgroundColor: "transparent",
  transition: "all 0.3s ease",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.contrastText,
  fontSize: "14px",
  fontWeight: "bold",
  zIndex: 1,
  transform: isactive === "true" ? "rotate(180deg)" : "rotate(0deg)",
}))

const LogoContainer = styled(Box)({
  width: "35px",
  height: "35px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})

const IconContainer = styled(Box)<{ isactive: string }>(({ isactive }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "40px",
  opacity: isactive === "true" ? 1 : 0.5,
  transition: "all 0.3s ease",
  zIndex: 0,
  fontWeight: isactive === "true" ? "bold" : "normal",
}))

interface LanguageToggleProps {}

const LanguageToggle: React.FC<LanguageToggleProps> = () => {
  const handleToggle = () => {
    setLanguage(isEnglish ? "zh" : "en")
  }
  const { language, setLanguage } = useLanguage()
  const isEnglish = useMemo(() => {
    return language === "en"
  }, [language])
  return (
    <Box
      sx={{
        py: 1,
        px: 2,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography>
        <English>Language</English>
        <Chinese>語言</Chinese>
      </Typography>
      <ToggleContainer onClick={handleToggle}>
        <IconContainer
          className={!isEnglish ? "active-icon" : "inactive-icon"}
          isactive={(!isEnglish).toString()}
        >
          中
        </IconContainer>
        <ToggleButton className="logoButton" isactive={isEnglish.toString()}>
          <LogoContainer>
            <LogoSVG width="100%" height="100%" />
          </LogoContainer>
        </ToggleButton>
        <IconContainer
          className={isEnglish ? "active-icon" : "inactive-icon"}
          isactive={isEnglish.toString()}
        >
          En
        </IconContainer>
      </ToggleContainer>
    </Box>
  )
}

export default LanguageToggle

// This ensures the file is treated as a module
export {}
