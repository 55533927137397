import React from "react"
import { useLanguage } from "../context/LanguageProvider"

type ChineseProps = {
  children: React.ReactNode
}

const Chinese: React.FC<ChineseProps> = ({ children }) => {
  const { language } = useLanguage()
  return language === "zh" ? <>{children}</> : <></>
}

export default Chinese
