import React, { useState } from "react"
import { Box, Button, Grid } from "@mui/material"
import { MarketPlaceTable } from "../../../component"
import { MusicAPI } from "../../../api"
import { FilterState } from "../../../types/marketPlace"
import useMUIMediaQuery from "../../../hooks/useMUIMedia"
import SortIcon from "@mui/icons-material/Sort"
import MarketPlaceSortMenu from "../../../component/MarketPlace/SortMenu/MarketPlaceSortMenu"
import MobileMarketPlaceSortMenu from "../../../component/MarketPlace/SortMenu/MobileMarketPlaceSortMenu"

/**
 * SoundEffect tab in market place page
 */
export default function SoundEffect() {
  const [marketPlaceData, setMarketPlaceData] = React.useState([])
  const [, setError] = React.useState("")
  const { isMobile } = useMUIMediaQuery()

  // Floating UI control
  const [openSortMenu, setOpenSortMenu] = useState(false)

  // Filtering and sorting state for getMarketPlaceData
  const [filter, setFilter] = React.useState<FilterState>({
    style: [],
    tempo: [],
    sortBy: "uploadTimestamp",
    sortOrder: "DESC",
  })

  React.useEffect(() => {
    const getMarketPlaceData = async () => {
      const response = await MusicAPI.retrieve({
        pageIndex: 0,
        pageSize: 0,
        styles: filter?.style,
        tempo: filter?.tempo,
        sortBy: filter?.sortBy,
        sortOrder: filter?.sortOrder,
        musicType: "SOUNDEFFECT",
      })
      if (response?.err) {
        setError(response?.err)
        return
      }
      setMarketPlaceData(response?.res)
    }
    getMarketPlaceData()
  }, [filter])

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            {isMobile ? (
              <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
                <Button
                  variant="outlined"
                  startIcon={<SortIcon />}
                  onClick={() => setOpenSortMenu(true)}
                  sx={{ textTransform: "none" }}
                >
                  Sort
                </Button>
              </Box>
            ) : (
              <MarketPlaceSortMenu filter={filter} setFilter={setFilter} />
            )}
            <MarketPlaceTable data={marketPlaceData} />
          </Grid>
        </Grid>
      </Box>

      {isMobile && (
        <MobileMarketPlaceSortMenu
          filter={filter}
          setFilter={setFilter}
          open={openSortMenu}
          onClose={() => setOpenSortMenu(false)}
        />
      )}
    </>
  )
}
