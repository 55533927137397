import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { Box, Button, Card, CardContent, Typography } from "@mui/material"
import { PopupButton } from "@typeform/embed-react"
import React from "react"
import Chinese from "theme/Chinese"
import English from "theme/English"
import { colors } from "../../theme/colors"

type SubscriptionPlanCardProps = {
  title: string
  priceText: string
  billingCycle: string
  features: string[]
  buttonText: string
  isExistingPlan: boolean
  planType: string
  isContactUs?: boolean
  onClick?: () => void
  isSelected?: boolean
}

const SubscriptionPlanCard: React.FC<SubscriptionPlanCardProps> = ({
  title,
  priceText: price,
  billingCycle,
  features,
  buttonText,
  isExistingPlan,
  planType,
  isContactUs,
  onClick,
  isSelected,
}) => {
  return (
    <Card sx={{ backgroundColor: colors.darkGrey2, color: "#fff", maxWidth: 300 }}>
      <Box
        sx={{
          backgroundColor: colors.grey3,
          padding: 2,
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
          border: isSelected ? `2px solid ${colors.orange}` : `1px solid ${colors.grey}`,
        }}
      >
        <Typography variant="subtitle1" gutterBottom sx={{ color: "#fff" }}>
          {title}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "baseline" }}>
          <Typography variant="h4" component="div" sx={{ color: "#fff", marginRight: 1 }}>
            {price}
          </Typography>
          <Typography variant="body2" sx={{ color: "#aaa" }}>
            {billingCycle}
          </Typography>
        </Box>
      </Box>
      <CardContent sx={{ padding: 2 }}>
        <Box component="ul" sx={{ paddingLeft: 2, marginBottom: 2 }}>
          {features.map((feature, index) => (
            <Typography
              component="li"
              variant="body2"
              key={index}
              sx={{ color: "#fff", marginBottom: 1 }}
            >
              {feature}
            </Typography>
          ))}
        </Box>
        {isContactUs && (
          <Button variant="contained" fullWidth sx={{ backgroundColor: "#ff7d00", color: "#fff" }}>
            <PopupButton
              id="uxTdqdR7"
              style={{
                backgroundColor: "inherit",
                color: "inherit",
                borderRadius: "inherit",
                padding: "inherit",
                width: "inherit",
                textTransform: "inherit",
                border: "inherit",
                fontFamily: "inherit",
                fontSize: "inherit",
              }}
              className="typeform-button"
            >
              <English>Contact Us</English>
              <Chinese>聯絡我們</Chinese>
            </PopupButton>
          </Button>
        )}
        {!isExistingPlan && buttonText && (
          <Button
            variant="contained"
            fullWidth
            sx={{ backgroundColor: "#ff7d00", color: "#fff" }}
            endIcon={<ArrowForwardIcon />}
            onClick={onClick}
          >
            {buttonText}
          </Button>
        )}
      </CardContent>
      {isExistingPlan && (
        <CardContent sx={{ backgroundColor: "#f5c744" }}>
          <Box sx={{ textAlign: "center", backgroundColor: "#f5c744" }}>
            <Typography variant="caption" sx={{ color: "#000" }}>
              <English>Existing Plan</English>
              <Chinese>現有方案</Chinese>
            </Typography>
          </Box>
        </CardContent>
      )}
    </Card>
  )
}

export default SubscriptionPlanCard
