import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline"
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TableHead,
  Typography,
} from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import { formatDistance } from "date-fns"
import { enUS, zhTW } from "date-fns/locale"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CartAPI, DownloadAPI, SavedMusicAPI } from "../../../api"
import WMC_LOGO from "../../../assets/brands/logo-sq.png"
import { translateMusicStyle } from "../../../component/MusicStyleList/musicStyleData"
import ShareToPopUp from "../../../component/ShareToPopUp/ShareToPopUp"
import { useCart } from "../../../context/CartProvider"
import { useLanguage } from "../../../context/LanguageProvider" // new import
import { useMusicPlayer } from "../../../context/MusicProvider"
import { useUserInfo } from "../../../context/UserInfoProvider"
import Chinese from "../../../theme/Chinese"
import { colors } from "../../../theme/colors"
import English from "../../../theme/English"
import { MarketPlaceItem } from "../../../types/marketPlace"
import { createDownloadUrl, formatSecondsToMMSS } from "../../../util"
import { Buttons } from "./Buttons"

const ICON_SIZE = 64

const TABLE_HEADER = [
  {
    type: "musicInfo",
    label: (
      <>
        <English>Music information</English>
        <Chinese>關於</Chinese>
      </>
    ),
  },
  {
    type: "duration",
    label: (
      <>
        <English>Duration</English>
        <Chinese>時長</Chinese>
      </>
    ),
  },
  {
    type: "musicStyle",
    label: (
      <>
        <English>Music style</English>
        <Chinese>類別</Chinese>
      </>
    ),
  },
  {
    type: "musicType",
    label: (
      <>
        <English>Music type</English>
        <Chinese>種類</Chinese>
      </>
    ),
  },
  {
    type: "downloads",
    label: (
      <>
        <English>Downloads</English>
        <Chinese>下載次數</Chinese>
      </>
    ),
  },
  {
    type: "publishDate",
    label: (
      <>
        <English>Publish date</English>
        <Chinese>推出日期</Chinese>
      </>
    ),
  },
]

/**
 * Table in market place
 */
export default function MarketPlaceTable({ data }: { data: MarketPlaceItem[] }) {
  const { playSong, currentSong, stopSong, isPlaying } = useMusicPlayer()
  const { fetchCartItems } = useCart()
  // Initialize savedMusics from local storage
  const [savedMusics, setSavedMusics] = useState<string[]>(() => {
    const saved = localStorage.getItem("savedMusics")
    return saved ? JSON.parse(saved) : []
  })
  const [openQuotaDialog, setOpenQuotaDialog] = useState(false)
  const { userToken } = useUserInfo()
  const { language } = useLanguage() // new hook
  const [loadingActions, setLoadingActions] = useState<{ [key: string]: string }>({})

  // Function to set loading state for a specific action and music ID
  const setLoading = (musicId: string, action: string, isLoading: boolean) => {
    setLoadingActions((prev) => {
      if (isLoading) {
        return { ...prev, [`${musicId}-${action}`]: action }
      } else {
        const newState = { ...prev }
        delete newState[`${musicId}-${action}`]
        return newState
      }
    })
  }

  // Check if a specific action is loading
  const isLoading = (musicId: string, action: string) => {
    return !!loadingActions[`${musicId}-${action}`]
  }

  useEffect(() => {
    const fetchSavedMusics = async () => {
      try {
        const response = await SavedMusicAPI.getSavedMusic()
        if (response?.err) {
          console.error("Failed to get saved musics")
          return
        }
        const apiSaved = response.res.map((t: { musicId: { _id: string } }) => t.musicId._id)
        setSavedMusics(apiSaved)
        localStorage.setItem("savedMusics", JSON.stringify(apiSaved))
      } catch (err) {
        console.error("Failed to get saved musics:", err)
      }
    }
    if (userToken) fetchSavedMusics()
  }, [userToken])
  const handleAddToCart = async (musicId: string) => {
    setLoading(musicId, "cart", true)
    try {
      const response = await CartAPI.addToCart(musicId)
      if (response?.err) {
        console.error("Failed to add item to cart")
      } else {
        console.log("Item added to cart:", response)
        fetchCartItems()
      }
    } catch (err) {
      console.error("Failed to add item to cart:", err)
    } finally {
      setLoading(musicId, "cart", false)
    }
  }

  const handleDownload = async (musicItem: MarketPlaceItem) => {
    setLoading(musicItem._id, "download", true)
    const { purchased, projectName, _id } = musicItem
    let fileUrl = ""
    let url = ""
    try {
      const response = await DownloadAPI.recordDownload(_id)
      if (response?.err) {
        console.error("Failed to download music")
        return
      }
      if (response.res.success === false) {
        setOpenQuotaDialog(true)
        return
      }
      console.log("downloaded:", response)
      url = response.res.watermarkUrl ?? response.res.downloadUrl
    } catch (err) {
      console.error("Failed to download music:", err)
    }
    fileUrl = createDownloadUrl(url)

    if (!fileUrl) return

    try {
      // Fetch the file as a blob
      const response = await fetch(fileUrl)
      if (!response.ok) {
        throw new Error("Network response was not ok")
      }
      const blob = await response.blob()

      // Create a temporary URL
      const url = URL.createObjectURL(blob)

      // Create a link element
      const a = document.createElement("a")
      a.href = url
      // Set a default file name or use something dynamic
      a.download = projectName
        ? `${projectName}${purchased ? "" : "-wm"}.mp3`
        : "downloaded_file.mp3"
      document.body.appendChild(a)
      a.click()

      // Clean up
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
    } catch (error) {
      console.error("Download failed:", error)
    } finally {
      setLoading(musicItem._id, "download", false)
    }
  }

  const handleBuyNow = async (musicId: string) => {
    setLoading(musicId, "cart", true)
    try {
      await handleAddToCart(musicId)
      navigate(`/shoppingCart`)
    } catch (error) {
      console.error("Buy now failed:", error)
      setLoading(musicId, "cart", false)
    }
  }
  const [openShareDialog, setOpenShareDialog] = useState(false)
  const [sharingMarketPlaceItem, setSharingMarketPlaceItem] = useState<MarketPlaceItem | null>(null)

  const handleShareClick = (item: MarketPlaceItem) => {
    setSharingMarketPlaceItem(item) // Set the selected item to share
    setOpenShareDialog(true) // Open the share dialog
  }

  const handleShareDialogClose = () => {
    setOpenShareDialog(false)
    setSharingMarketPlaceItem(null) // Clear the selected item after sharing
  }

  const handleSaveMusic = async (musicId: string) => {
    setLoading(musicId, "save", true)
    try {
      const response = await SavedMusicAPI.saveMusic(musicId)
      if (response?.err) {
        console.error("Failed to save music")
      } else {
        console.log("Music saved:", response)
        const newSaved = [...savedMusics, musicId]
        setSavedMusics(newSaved)
        localStorage.setItem("savedMusics", JSON.stringify(newSaved))
      }
    } catch (err) {
      console.error("Failed to save music:", err)
    } finally {
      setLoading(musicId, "save", false)
    }
  }

  const handleUnsaveMusic = async (musicId: string) => {
    setLoading(musicId, "save", true)
    try {
      const response = await SavedMusicAPI.unsaveMusic(musicId)
      if (response?.err) {
        console.error("Failed to unsave music")
      } else {
        console.log("Music unsaved:", response)
        const newSaved = savedMusics.filter((id) => id !== musicId)
        setSavedMusics(newSaved)
        localStorage.setItem("savedMusics", JSON.stringify(newSaved))
      }
    } catch (err) {
      console.error("Failed to unsave music:", err)
    } finally {
      setLoading(musicId, "save", false)
    }
  }

  const navigate = useNavigate()

  return (
    <Box sx={{ width: "100%", display: "table", tableLayout: "fixed", whiteSpace: "nowrap" }}>
      <TableContainer component={Paper}>
        <Table sx={{}} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ height: 64, bgcolor: colors.orange }}>
              {TABLE_HEADER.map(({ type, label }) => (
                <TableCell key={type} sx={{ fontSize: 16, color: "white" }}>
                  {label}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.map((row) => {
              const projectImageUrl = createDownloadUrl(row?.image?.downloadUrl)
              const dateDistance = formatDistance(new Date(row?.uploadTimestamp), new Date(), {
                locale: language === "zh" ? zhTW : enUS,
              })
              return (
                <TableRow
                  key={row?._id}
                  hover
                  sx={{
                    height: 100,
                    "&:nth-of-type(n)": { backgroundColor: "background.default" },
                    "&:last-child td, &:last-child th": { border: "none" },
                  }}
                >
                  <TableCell align="left" scope="row">
                    <Stack direction="row" spacing={4} alignItems={"center"}>
                      <img
                        src={projectImageUrl}
                        alt="thumbnail"
                        width={ICON_SIZE}
                        height={ICON_SIZE}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null // prevents looping
                          currentTarget.src = WMC_LOGO
                        }}
                      />
                      {currentSong?._id === row._id && isPlaying ? (
                        <IconButton aria-label="player-button" onClick={() => stopSong()}>
                          <PauseCircleOutlineIcon />
                        </IconButton>
                      ) : (
                        <IconButton aria-label="player-button" onClick={() => playSong(row)}>
                          <PlayCircleOutlineIcon />
                        </IconButton>
                      )}
                      <Box onClick={() => navigate(`/musicDetail/${row._id}`)}>
                        <Typography variant="body1" fontWeight={"bold"}>{`${
                          row?.projectName || "--"
                        }`}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {row?.project?.users?.find((t) => t.role === "LEAD")?.user.name ?? "--"}
                        </Typography>
                      </Box>
                    </Stack>
                  </TableCell>

                  <TableCell>
                    <Stack direction="row">
                      <Typography variant="body2">
                        {formatSecondsToMMSS(Number(row?.duration)) ?? "--"}
                      </Typography>
                    </Stack>
                  </TableCell>

                  <TableCell>
                    <Typography variant="body2">
                      {row?.styles
                        ?.map((style: string) => translateMusicStyle(style, language))
                        .join(", ")}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    {row?.musicType === "STANDARD" ? (
                      <Typography variant="body2">
                        <English>Standard</English>
                        <Chinese>基礎</Chinese>
                      </Typography>
                    ) : row?.musicType === "SOUNDEFFECT" ? (
                      <Typography variant="body2">
                        <English>Sound Effect</English>
                        <Chinese>音效</Chinese>
                      </Typography>
                    ) : row?.musicType === "FREE" ? (
                      <Typography variant="body2">
                        <English>FREE</English>
                        <Chinese>免費</Chinese>
                      </Typography>
                    ) : (
                      <Stack direction="row" spacing={1}>
                        <Typography variant="body2">{row?.currency ?? "--"}</Typography>
                        <Typography variant="body2">{row?.price ?? "--"}</Typography>
                      </Stack>
                    )}
                  </TableCell>

                  <TableCell>
                    <span>
                      {row?.downloadCount?.toLocaleString() ?? "--"} <English>downloads</English>
                      <Chinese>次下載</Chinese>
                    </span>
                  </TableCell>

                  <TableCell>
                    {<span>{dateDistance ?? "--"}</span>} <English>ago</English>
                    <Chinese>前</Chinese>
                  </TableCell>

                  <TableCell align="right" scope="row">
                    {row?.purchased ||
                    row?.musicType === "STANDARD" ||
                    row?.musicType === "SOUNDEFFECT" ||
                    row?.musicType === "FREE" ? (
                      <Buttons.Download
                        onClick={() => handleDownload(row)}
                        isLoading={isLoading(row._id, "download")}
                      />
                    ) : (
                      <Buttons.BuyNowOrAddToCart
                        onBuyNowClick={() => handleBuyNow(row?._id)}
                        onAddToCartClick={() => handleAddToCart(row?._id)}
                        isLoading={isLoading(row._id, "cart")}
                      />
                    )}
                    <Buttons.Share sx={{ mx: 0.5 }} onClick={() => handleShareClick(row)} />
                    <Buttons.Bookmark
                      onClick={() => {
                        savedMusics.includes(row._id)
                          ? handleUnsaveMusic(row._id)
                          : handleSaveMusic(row._id)
                      }}
                      saved={savedMusics.includes(row._id)}
                      isLoading={isLoading(row._id, "save")}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {data?.length === 0 && (
        <Typography variant="body2" color="text.secondary" align="center">
          <English>Cannot find any music, please reload</English>
          <Chinese>找不到任何音樂，請重新加載</Chinese>
        </Typography>
      )}

      {sharingMarketPlaceItem && (
        <ShareToPopUp
          open={openShareDialog}
          onClose={handleShareDialogClose}
          musicId={sharingMarketPlaceItem._id}
          musicName={sharingMarketPlaceItem.name}
        />
      )}

      <Dialog open={openQuotaDialog} onClose={() => setOpenQuotaDialog(false)}>
        <DialogTitle>
          <English>Download Quota Reached</English>
          <Chinese>下載配額已達上限</Chinese>
        </DialogTitle>
        <DialogContent>
          <English>You have reached your download quota this month. Upgrade Now!</English>
          <Chinese>您本月的下載配額已用盡。立即升級！</Chinese>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => navigate("/subscription")}>Upgrade Plan</Button> */}

          <Button onClick={() => setOpenQuotaDialog(false)}>
            <English>Close</English>
            <Chinese>關閉</Chinese>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
