import { Divider, useMediaQuery } from "@mui/material"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Page, PageContainer } from "../../component"
import Chinese from "../../theme/Chinese"
import English from "../../theme/English"
import PurchasingRecord from "./PurchasingRecord"
import SavedMusic from "./SavedMusic"
import SubscriptionTab from "./SubscriptionTab"
import DownloadRecord from "./DownloadRecord"

type HistoryTab = "purchased" | "subscription" | "saved" | "downloaded"
function isHistoryTab(tab: string): tab is HistoryTab {
  return ["purchased", "subscription", "saved", "downloaded"].includes(tab)
}
/**
 * History page
 */
export default function HistoryPage() {
  const isMobile = useMediaQuery("(max-width:600px)")

  const { tab } = useParams()

  const [activeTab, setActiveTab] = React.useState<HistoryTab>(
    tab && isHistoryTab(tab) ? tab : "purchased"
  )
  useEffect(() => {
    if (tab && isHistoryTab(tab)) {
      setActiveTab(tab)
    }
  }, [tab])

  return (
    <div>
      <Page pageId={HistoryPage} noPadding>
        <PageContainer>
          <Tabs
            value={activeTab}
            onChange={(event: React.SyntheticEvent, tab: HistoryTab) => setActiveTab(tab)}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab
              key={"purchased"}
              value={"purchased"}
              label={
                <>
                  <English>Purchasing Record</English>
                  <Chinese>購買記錄</Chinese>
                </>
              }
              sx={{ textTransform: "none" }}
            />
            <Tab
              key={"subscription"}
              value={"subscription"}
              label={
                <>
                  <English>Subscription</English>
                  <Chinese>訂閱</Chinese>
                </>
              }
              sx={{ textTransform: "none" }}
            />
            <Tab
              key={"saved"}
              value={"saved"}
              label={
                <>
                  <English>Saved Music</English>
                  <Chinese>已儲存音樂</Chinese>
                </>
              }
              sx={{ textTransform: "none" }}
            />
            <Tab
              key={"downloaded"}
              value={"downloaded"}
              label={
                <>
                  <English>Download Record</English>
                  <Chinese>下載記錄</Chinese>
                </>
              }
              sx={{ textTransform: "none" }}
            />
          </Tabs>

          <Divider sx={{ mb: 2 }} />

          {activeTab === "purchased" && <PurchasingRecord />}
          {activeTab === "subscription" && <SubscriptionTab />}
          {activeTab === "saved" && <SavedMusic />}
          {activeTab === "downloaded" && <DownloadRecord />}
        </PageContainer>
      </Page>
    </div>
  )
}
