/* eslint-disable import/no-unresolved */
import { Box } from "@mui/material"
import useMUIMediaQuery from "hooks/useMUIMedia"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode, Navigation } from "swiper/modules"
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/navigation"

interface SwipeableListGroupProps {
  items: {
    label: string
    imageSrc: string
  }[]
}

/**
 * Swipeable carousel for image display
 */
export default function SwipeableListGroup({ items }: SwipeableListGroupProps) {
  const { isMobile } = useMUIMediaQuery()

  // Default values
  const DEFAULT_CONTAINER_WIDTH = "auto"
  const DEFAULT_CONTAINER_HEIGHT = "auto"
  const DEFAULT_SLIDE_WIDTH = isMobile ? "100%" : "auto"
  const DEFAULT_SLIDE_HEIGHT = isMobile ? "auto" : 428
  const DEFAULT_GAP = 16

  return (
    <Box
      width={DEFAULT_CONTAINER_WIDTH}
      height={DEFAULT_CONTAINER_HEIGHT}
      sx={{
        display: "grid",
        overflow: "hidden",
        "--swiper-navigation-size": "28px",
        ".swiper-button-next": {
          color: "#ffffff66",
          // pointerEvents: "none"
        },
        ".swiper-button-prev": {
          color: "#ffffff66",
          // pointerEvents: "none"
        },
      }}
    >
      <Swiper
        style={{ width: "100%", justifyContent: "space-between" }}
        slidesPerView={"auto"}
        spaceBetween={DEFAULT_GAP}
        freeMode={true}
        navigation={true}
        modules={[FreeMode, Navigation]}
      >
        {items?.map((item, index) => (
          <SwiperSlide
            key={"carousel_" + index}
            style={{ width: DEFAULT_SLIDE_WIDTH, height: DEFAULT_SLIDE_HEIGHT }}
          >
            <Box
              component="img"
              src={item?.imageSrc}
              alt={item?.label}
              sx={{
                width: DEFAULT_SLIDE_WIDTH,
                height: DEFAULT_SLIDE_HEIGHT,
                objectFit: "contain",
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}
