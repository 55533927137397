import { FormControl, FormGroup, FormHelperText, FormLabel, Grid } from "@mui/material"
import { GridSize } from "@mui/material/Grid"
import compact from "lodash/compact"
import startCase from "lodash/startCase"
import React, { useCallback } from "react"
import { ImageCheckBox } from ".."
import { useLanguage } from "../../context/LanguageProvider"
import { musicStyles } from "../UserForm/user"
import { translateMusicStyle } from "./musicStyleData"

const styleListDefaultCol = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3,
}

interface MusicStyleListProps {
  handleCheckBoxToggleChange: (_event: React.ChangeEvent<HTMLInputElement>, _key: string) => void
  styleOfInterest: string[]
  stateKey: string
  displayTitle?: boolean
  cols?: {
    xs?: GridSize
    sm?: GridSize
    md?: GridSize
    lg?: GridSize
  }
  editFormErrorDisplay?: (_: string) => void
  getErrorMessage?: (_key: string) => string | undefined
  shouldDisplayError?: (_: string) => boolean
}

/**
 * List out music styles in WMC
 */
export default function MusicStyleList({
  handleCheckBoxToggleChange,
  editFormErrorDisplay = () => {},
  getErrorMessage = () => undefined,
  shouldDisplayError = () => false,
  styleOfInterest,
  stateKey,
  cols = styleListDefaultCol,
  displayTitle = true,
}: MusicStyleListProps) {
  const displayError = !!(
    shouldDisplayError("styleOfInterest") && getErrorMessage("styleOfInterest")
  )
  const { language } = useLanguage()

  const createMusicStyle = useCallback(() => {
    return compact(musicStyles as string[]).map((value: string, index: number) => (
      <Grid key={`${value}${index}`} container flexDirection="row" item {...cols} py={0.5}>
        <ImageCheckBox
          label={translateMusicStyle(value, language)}
          value={value}
          image={value}
          handleCheckBoxToggleChange={(e) => {
            editFormErrorDisplay("styleOfInterest")
            handleCheckBoxToggleChange(e, stateKey)
          }}
          selectedItems={styleOfInterest}
        />
      </Grid>
    ))
  }, [cols, editFormErrorDisplay, handleCheckBoxToggleChange, stateKey, styleOfInterest])

  return (
    <Grid container item xs={12} sx={{ px: 2 }}>
      <FormControl component="fieldset" error={displayError}>
        {displayTitle && <FormLabel component="legend">{startCase("styleOfInterest")}</FormLabel>}
        {displayError && (
          <FormHelperText id="styleOfInterest-errors">
            {getErrorMessage("styleOfInterest")}
          </FormHelperText>
        )}
        <FormGroup
          sx={{
            flexDirection: "row",
            "& .MuiTypography-root": { flex: 1 },
            "& .MuiFormControlLabel-label": {
              display: "flex",
              alignItems: "center",
            },
          }}
          data-testid="musicStyleList"
        >
          {createMusicStyle()}
        </FormGroup>
      </FormControl>
    </Grid>
  )
}
