import { Box, Button, Stack, Typography } from "@mui/material"
import { Dispatch, SetStateAction, useState } from "react"
import { FilterState } from "../../../../types/marketPlace"
import FilterMenuItem from "./FilterMenuItem"
// @ts-ignore
import { musicStyle } from "@womucon/share"
import { translateMusicStyle } from "../../../MusicStyleList/musicStyleData"
import { useLanguage } from "../../../../context/LanguageProvider"
import English from "../../../../theme/English"
import Chinese from "../../../../theme/Chinese"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"

/**
 * Filter by music style in market place
 */
export default function FilterByMusicStyleMenu({
  filter,
  setFilter,
}: {
  filter: FilterState
  setFilter: Dispatch<SetStateAction<FilterState>>
}) {
  const styleArray = filter?.style ?? []
  const { language } = useLanguage()

  const [showFullMenu, setShowFullMenu] = useState(false)
  const MUSIC_STYLE = showFullMenu ? musicStyle : musicStyle?.slice(0, 8)

  const updateFilter = (newStyle: string) => {
    if (!newStyle) return
    setFilter((prevFilter) => {
      const updatedStyles = prevFilter?.style?.includes(newStyle)
        ? prevFilter?.style?.filter((style) => style !== newStyle)
        : [...prevFilter.style, newStyle]
      return { ...prevFilter, style: updatedStyles }
    })
  }

  const resetFilter = () => {
    setFilter((prevFilter) => {
      return { ...prevFilter, style: [] }
    })
  }

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="body1">
          <English>Filter by music style</English>
          <Chinese>按音樂風格篩選</Chinese>
        </Typography>
        <Button sx={{ textTransform: "none" }} onClick={() => resetFilter()}>
          <English>Clear All</English>
          <Chinese>清除所有</Chinese>
        </Button>
      </Box>

      <Box>
        {MUSIC_STYLE?.map((item: string) => {
          const focused = styleArray?.includes(item)
          return (
            <FilterMenuItem
              key={`style_${item}`}
              image={item}
              focused={focused}
              label={translateMusicStyle(item, language)}
              onClick={() => updateFilter(item)}
            />
          )
        })}
      </Box>

      <Stack>
        <Button sx={{ textTransform: "none" }} onClick={() => setShowFullMenu(!showFullMenu)}>
          {showFullMenu ? <RemoveIcon /> : <AddIcon />}
          {showFullMenu ? <English>SHOW LESS</English> : <English>SHOW MORE</English>}
          {showFullMenu ? <Chinese>顯示較少</Chinese> : <Chinese>顯示更多</Chinese>}
        </Button>
      </Stack>
    </Box>
  )
}
