import { MusicNote, PeopleAltOutlined } from "@mui/icons-material"
import { Badge, Box, Chip } from "@mui/material"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import { Link } from "react-router-dom"
import { IconWithText } from "."
import WMC_LOGO from "../assets/brands/logo-sq.png"
import { useLanguage } from "../context/LanguageProvider"
import useMUIMediaQuery from "../hooks/useMUIMedia"
import { ProjectProps } from "../types"
import { createDownloadUrl } from "../util"
import { translateInstrumentText } from "./InstrumentList/instrumentData"
import { translateMusicStyle } from "./MusicStyleList/musicStyleData"

export interface ProjectItemProps {
  /**
   * A WMC project
   */
  project: ProjectProps
}

/**
 * List of project
 */
export default function ProjectItem({ project }: ProjectItemProps) {
  const { isMobileUp } = useMUIMediaQuery()
  const { image, styles, instruments, status } = project
  const projectImageUrl = image?.downloadUrl ? createDownloadUrl(image?.downloadUrl) : WMC_LOGO
  const { language } = useLanguage()

  return (
    <Card
      sx={{
        position: "relative",
        m: "5px",
        width: {
          xs: "100%",
          md: "calc(50% - 20px)",
          lg: "calc(33% - 10px)",
          xl: "calc(20% - 10px)",
        },
        display: {
          xs: "flex",
          md: "block",
        },
      }}
    >
      <Badge
        badgeContent={language === "en" ? status : status === "PRIVATE" ? "私人" : "公開"}
        color={status === "PRIVATE" ? "info" : "primary"}
        sx={{
          position: "absolute",
          top: 20,
          left: 40,
          "& .MuiBadge-badge": {
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      ></Badge>
      <Link to={`/projects/${project._id}`}>
        <CardMedia
          sx={{
            height: {
              xs: "100%",
              md: 300,
            },
            width: {
              xs: window.innerWidth / 3,
              md: "100%",
            },
          }}
          image={projectImageUrl}
          title="project cover img"
        />
      </Link>
      <Box
        sx={{
          display: { xs: "flex", md: "block" },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <CardContent>
          <Typography gutterBottom variant="h6" component="div" noWrap>
            <Link to={`/projects/${project._id}`}>{project.name}</Link>
          </Typography>
        </CardContent>
        <CardActions>
          {[
            {
              iconComponent: <MusicNote />,
              text: `${project.users.length} ${
                isMobileUp ? (language === "en" ? "Musicians" : "音樂家") : ""
              }`,
            },
            {
              iconComponent: <PeopleAltOutlined />,
              text: `${project.followers.length} ${
                isMobileUp ? (language === "en" ? "Followers" : "追隨者") : ""
              }`,
            },
          ].map(({ iconComponent, text }) => {
            return <IconWithText key={text} iconComponent={iconComponent} text={text} />
          })}
        </CardActions>
        <CardActions sx={{ flexWrap: "wrap" }}>
          {instruments.map((ins) => (
            <Chip
              key={ins}
              label={translateInstrumentText(ins, language)}
              variant="filled"
              sx={{ mt: 1 }}
            />
          ))}
          {styles.map((style) => (
            <Chip
              key={style}
              label={translateMusicStyle(style, language)}
              variant="outlined"
              sx={{ mt: 1 }}
            />
          ))}
        </CardActions>
      </Box>
    </Card>
  )
}
