export const translateMood = (mood: string, language: "en" | "zh"): string => {
  const translations: { [key: string]: string } = {
    angry: "憤怒",
    chill: "放鬆",
    dark: "黑暗",
    dramatic: "戲劇性",
    epic: "史詩",
    exciting: "刺激",
    funny: "有趣",
    happy: "快樂",
    inspiring: "激勵",
    sad: "悲傷",
    scary: "恐怖",
    upbeat: "歡快",
  }

  if (language === "zh") {
    return translations[mood.toLowerCase()] || mood
  } else {
    return mood.charAt(0).toUpperCase() + mood.slice(1)
  }
}
