import React from "react"
import { useLanguage } from "../context/LanguageProvider"

type EnglishProps = {
  children: React.ReactNode
}

const English: React.FC<EnglishProps> = ({ children }) => {
  const { language } = useLanguage()
  return language === "en" ? <>{children}</> : <></>
}

export default English
