import {
  ArrangementPNG,
  BassesPNG,
  BrassPNG,
  ComposerPNG,
  DjPNG,
  DrumsPNG,
  FolkAndTraditionalPNG,
  GuitarPNG,
  KeyboardMidiPNG,
  OthersPNG,
  PianoPNG,
  ProducerPNG,
  SongWriterPNG,
  SoundEngineerPNG,
  StringsPNG,
  VocalPNG,
  WoodwindsPNG,
} from "../../assets/instruments"

export interface InstrumentCategoriesProps {
  label: string
  value?: string
  options?: {
    label: string
    value: string
  }[]
}

// Mapping Icon with Instrument label
// Refer to: womucon-apps/packages/share/constant/instruments.js
export const renderIcon = (instrumentLabel: string) => {
  switch (instrumentLabel) {
    case "Guitar":
      return GuitarPNG
    case "Folk & Traditional Stringed Instrument":
      return FolkAndTraditionalPNG
    case "Basses":
      return BassesPNG
    case "Drums/Percussion":
      return DrumsPNG
    case "Keyboard & MIDI":
      return KeyboardMidiPNG
    case "DJ":
      return DjPNG
    case "Piano":
      return PianoPNG
    case "Vocal (Male)":
      return VocalPNG
    case "Vocal (Female)":
      return VocalPNG
    case "WoodWinds":
      return WoodwindsPNG
    case "Brass":
      return BrassPNG
    case "String":
      return StringsPNG
    case "Mix Engineer":
      return SoundEngineerPNG
    case "Music Producer":
      return ProducerPNG
    case "Songwriter":
      return SongWriterPNG
    case "Music Arranger":
      return ProducerPNG
    case "Composer":
      return ComposerPNG
    case "Arrangement":
      return ArrangementPNG
    case "Other":
      return OthersPNG
    default:
      console.log("No icon")
  }
}

// Added function to translate instrument text based on language
export const translateInstrumentText = (label: string, language: "en" | "zh"): string => {
  // Normalize label to lowercase for matching keys with spaces
  const key = label.trim().toLowerCase()
  const translations: { [key: string]: string } = {
    guitar: "吉他",
    "folk & traditional stringed instrument": "民謠及傳統弦樂器",
    basses: "貝斯",
    "drums/percussion": "鼓/打擊樂器",
    "keyboard & midi": "鍵盤及MIDI",
    dj: "DJ",
    piano: "鋼琴",
    "vocal (male)": "男聲",
    "vocal (female)": "女聲",
    woodwinds: "木管樂器",
    brass: "銅管樂器",
    string: "弦樂器",
    "mix engineer": "混音工程師",
    "music producer": "音樂製作人",
    songwriter: "創作人",
    "music arranger": "編曲",
    composer: "作曲家",
    arrangement: "編曲",
    other: "其他",
    // Existing sub-type translations
    "electric guitar": "電吉他",
    "acoustic guitar": "木吉他",
    "acoustic electric guitar": "電木吉他",
    // Additional sub-type translations (referring to instruments.js)
    // Folk & Traditional Stringed Instrument sub-types
    ukuleles: "尤克里里",
    banjos: "班卓琴",
    mandolins: "曼陀林",
    resonators: "共鳴琴",
    "lap steels": "膝上琴",
    "latin strings": "拉丁弦樂",
    banjoleles: "班喬雷勒",
    "cigar box instruments": "雪茄盒樂器",
    dulcimers: "魯茲米爾",
    ouds: "烏德琴",
    autoharp: "自動豎琴",
    // Basses sub-types
    "electric basses": "電貝斯",
    "acoustic basses": "原聲貝斯",
    "double basses": "低音大提琴",
    // Drums/Percussion sub-types (subset)
    "acoustic drum set": "原聲鼓組",
    "electronic drum set": "電子鼓組",
    snare: "小鼓",
    tambourines: "鈴鼓",
    cowbells: "牛鈴",
    "bar chimes and mark trees": "棒鈴與樹狀鈴",
    timbales: "通巴萊斯",
    "jam blocks": "敲擊塊",
    "steel pan drums": "鋼琴鼓",
    handpan: "手鼓",
    "bell trees": "鐘樹",
    cajons: "箱鼓",
    djembes: "珍貝",
    congas: "康加鼓",
    bongo: "邦戈鼓",
    doumbeks: "杜貝克鼓",
    darbukas: "達布卡鼓",
    tubanos: "杜巴諾鼓",
    "tongue drum": "舌鼓",
    "ocean drum": "海洋鼓",
    tabla: "塔布拉鼓",
    "frame drum": "框鼓",
    "djun djun": "杜君杜鼓",
    "talking drum": "談鼓",
    "singing bowl": "頌缽",
    // Keyboard & MIDI sub-types
    "digital piano": "數位鋼琴",
    synthesizers: "合成器",
    // Vocal (Male) sub-types
    "bass (male vocal)": "男低音",
    "baritone (male vocal)": "男中音",
    "tenor (male vocal)": "男高音",
    "alto (male vocal)": "男次高音",
    "mezzo soprano (male vocal)": "男中高音",
    "soprano (male vocal)": "男高音",
    "general (male vocal)": "男聲",
    // Vocal (Female) sub-types
    "bass (female vocal)": "女低音",
    "baritone (female vocal)": "女中音",
    "tenor (female vocal)": "女高音",
    "alto (female vocal)": "女次高音",
    "mezzo soprano (female vocal)": "女中高音",
    "soprano (female vocal)": "女高音",
    "general (female vocal)": "女聲",
    // WoodWinds sub-types
    clarinet: "單簧管",
    bassoons: "巴松管",
    "english horns": "英國管",
    oboes: "雙簧管",
    piccolos: "短笛",
    recorder: "錄音器",
    "alto flutes": "中音長笛",
    "bass flutes": "低音長笛",
    "alto saxophone": "中音薩克斯",
    "baritone saxophone": "男中音薩克斯",
    "bass saxophone": "低音薩克斯",
    "soprano saxophone": "高音薩克斯",
    "tenor saxophone": "次中音薩克斯",
    // Brass sub-types
    bugle: "喇叭",
    euphoniums: "大號",
    flugelhorns: "曲笛",
    mellophone: "中音號",
    sousaphones: "低音號",
    "alto horns": "高音號",
    "tenor horns": "次中音號",
    "baritone horns": "男中音號",
    "eb cornet": "Eb小號",
    "bb cornet": "Bb小號",
    "double horns": "雙號",
    "marching french horns": "行進法國號",
    "natural french horns": "自然法國號",
    "single french horns": "單號法國號",
    "bass trombones": "低音長號",
    "soprano trombones": "高音長號",
    "tenor trombones": "次中音長號",
    "valve trombones": "活塞長號",
    "bb trumpets": "Bb小號",
    "c trumpets": "C小號",
    "eb trumpets": "Eb小號",
    "d trumpets": "D小號",
    "bb tubas": "Bb大號",
    "c tubas": "C大號",
    "eb tubas": "Eb大號",
    "f tubas": "F大號",
    // String sub-types
    violins: "小提琴",
    violas: "中提琴",
    harps: "豎琴",
    cellos: "大提琴",
  }

  if (language === "zh") {
    return translations[key] || label
  } else {
    return label.charAt(0).toUpperCase() + label.slice(1)
  }
}
