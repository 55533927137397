//@ts-ignore
import { musicKeyList } from "@womucon/share"
import { UploadSongFormStateProps } from "../../types/uploadSongTypes"

export const musicKeys = musicKeyList

export const tempoMarks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 260,
    label: "260",
  },
]

export const UploadSongFormInitiateState: UploadSongFormStateProps = {
  name: "",
  description: "",
  instruments: [],
  styles: [],
  moods: [],
  themes: [],
  tempo: 120,
  musicKey: "",
  metreUpper: 4,
  metreLower: 4,
  musicType: "",
  currency: "",
  price: "",
}

export const MUSIC_TYPES = ["PREMIUM", "STANDARD", "SOUNDEFFECT", "FREE"]
