import { Grid, Typography, Box } from "@mui/material"
import { Page, PageContainer, PageTitle } from "../../component"
import { useLanguage } from "../../context/LanguageProvider"
import English from "../../theme/English"
import Chinese from "../../theme/Chinese"
// Import the image directly if there's an issue with the component
import promoteImage from "../../assets/jobCenter/promote.jpeg"

/**
 *
 * Page that lists all of the project
 * This is aka the Project/Job Centre Page
 */
export default function JobCenterPage() {
  const { language } = useLanguage()

  return (
    <Page pageId={JobCenterPage} noPadding>
      <PageContainer>
        <Grid container>
          <Grid item xs={12}>
            {/* Replace the component with a direct image */}
            <Box
              component="img"
              src={promoteImage}
              alt="Job Center Promotion"
              sx={{
                width: "50%",
                objectFit: "cover",
                display: "block",
                margin: "0 auto",
              }}
            />
          </Grid>
          <Grid item container xs={12} justifyContent={"flex-start"} columnSpacing={1}></Grid>
        </Grid>
      </PageContainer>
    </Page>
  )
}
