import { Grid, SelectChangeEvent, TextField, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { Dropdown, MusicStyleList } from "component"
import React from "react"
import { useLanguage } from "../../../context/LanguageProvider"
import { educations } from "../user"
import RecordingLevel from "./RecordingLevel"
import UserRecordingSoftware from "./UserRecordingSoftware"

interface MusicProfileProps {
  handleChangeEducation: (_: React.MouseEvent<HTMLElement, MouseEvent>, value: number) => void
  disabled?: boolean
  musicEducation: string
  recordingLevel: number
  styleOfInterest: string[]
  musicCertificate: string
  recordingSoftware: string[]
  handleSelectChange: (_: SelectChangeEvent<string>) => void
  handleTextChange: (_: React.ChangeEvent<HTMLInputElement>) => void
  handleCheckBoxToggleChange: (event: React.ChangeEvent<HTMLInputElement>, key: string) => void
  editFormErrorDisplay: (_: string) => void
  getErrorMessage: (key: string) => string | undefined
  shouldDisplayError: (_: string) => boolean
}

const MusicProfile = ({
  handleChangeEducation,
  handleTextChange,
  shouldDisplayError,
  editFormErrorDisplay,
  getErrorMessage,
  musicEducation,
  musicCertificate,
  recordingLevel,
  styleOfInterest,
  handleSelectChange,
  disabled = false,
  handleCheckBoxToggleChange,
  recordingSoftware,
}: MusicProfileProps) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("lg"))
  const { language: currentLanguage } = useLanguage() // added language hook
  const shouldDisplayCertificateError = !!(
    shouldDisplayError("musicCertificate") && getErrorMessage("musicCertificate")
  )
  const { language } = useLanguage()
  return (
    <Grid
      container
      columnGap={4}
      sx={{
        "& .MuiTextField-root": { my: 1 },
        "& .MuiFormControl-root": { my: 1 },
      }}
    >
      <Grid item sm={12} lg={5}>
        <TextField
          onBlur={() => editFormErrorDisplay("musicCertificate")}
          error={shouldDisplayCertificateError}
          helperText={shouldDisplayCertificateError ? getErrorMessage("musicCertificate") : ""}
          label={currentLanguage === "en" ? "Music Certificate" : "音樂證書"}
          fullWidth
          name="musicCertificate"
          value={musicCertificate}
          onChange={handleTextChange}
          disabled={disabled}
          inputProps={{ "data-testid": "mp-musicCertificate" }}
        />
      </Grid>
      {matches && <Grid item sm={12} lg={1}></Grid>}
      <Grid item sm={12} lg={5}>
        <Dropdown
          testId="mp-musicEducation"
          value={musicEducation}
          label={currentLanguage === "en" ? "Music Education" : "音樂教育"}
          onChange={handleSelectChange}
          options={
            language === "en"
              ? educations
              : ["高中", "副學士", "學士", "碩士", "博士", "以上都不是"]
          }
          name="musicEducation"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {currentLanguage === "en" ? "Self Recording Level" : "自錄等級"}
        </Typography>
      </Grid>
      <Grid item container xs={12} lg={10}>
        <RecordingLevel
          recordingLevel={recordingLevel}
          handleChangeEducation={handleChangeEducation}
        />
      </Grid>
      <Grid item container xs={12} lg={10}>
        <MusicStyleList
          getErrorMessage={getErrorMessage}
          shouldDisplayError={shouldDisplayError}
          editFormErrorDisplay={editFormErrorDisplay}
          styleOfInterest={styleOfInterest}
          stateKey="styleOfInterest"
          handleCheckBoxToggleChange={handleCheckBoxToggleChange}
        />
        <UserRecordingSoftware
          getErrorMessage={getErrorMessage}
          shouldDisplayError={shouldDisplayError}
          editFormErrorDisplay={editFormErrorDisplay}
          recordingSoftware={recordingSoftware}
          handleCheckBoxToggleChange={handleCheckBoxToggleChange}
        />
      </Grid>
    </Grid>
  )
}

export default MusicProfile
